import { FormattedMessage, useIntl } from "react-intl";
import { Button, Form, InputNumber, Modal, Select } from "antd";
import { Dispatch, SetStateAction, useState } from "react";
import './LoanCalculator.less';
import { TenureDropdownHelper } from "../../shared/components/tenure-dropdown/TenureDropdown";

interface Props {
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
}

const LoanCalculator = ({ visible, setVisible }: Props) => {
    const intl = useIntl();
    const [monthlyPayment, setMonthlyPayment] = useState<string>("MYR 0");
    const [showPayment, setShowPayment] = useState<boolean>(false);


    const onFinish = (fieldsValue: any) => {
        if (fieldsValue.amount && fieldsValue.tenure && fieldsValue.rate) {
            const interest = fieldsValue.amount * fieldsValue.tenure * (fieldsValue.rate / 100);
            const payment = (fieldsValue.amount + interest) / fieldsValue.tenure;
            setMonthlyPayment(`MYR ${Math.ceil(payment).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`);
            setShowPayment(true);
        }
    }

    return (
        <Modal title={<div className="font-subtitle1"><FormattedMessage id="LOAN_CALCULATOR" /></div>}
            visible={visible}
            footer=""
            closable={true}
            centered={true}
            maskClosable={true}
            onCancel={() => setVisible(false)}
        >
            <Form
                layout="vertical"
                onFinish={onFinish}
                initialValues={{ tenure: 3 }}
                className="loan-calculator"
            >
                <Form.Item name="amount" label={intl.formatMessage({ id: "FINANCING_AMOUNT" }).replace('{0}', intl.formatMessage({ id: 'MYR' }))} required
                    rules={[
                        {
                            required: true,
                            message: intl.formatMessage({ id: "REQUIRED_FIELD" })
                        },
                        {
                            pattern: /^[0-9]{1,11}(?:\.[0-9]{1,2})?$/,
                            message: ''
                        }
                    ]}>
                    <InputNumber min={0} placeholder={intl.formatMessage({ id: "FINANCING_AMOUNT" }).replace('{0}', intl.formatMessage({ id: 'MYR' }))} />
                </Form.Item>

                <Form.Item name="rate" label={`${intl.formatMessage({ id: "INTEREST_RATE" })} %`} required
                    rules={[
                        {
                            required: true,
                            message: intl.formatMessage({ id: "REQUIRED_FIELD" })
                        },
                        {
                            pattern: /^[0-9]{1,11}(?:\.[0-9]{1,3})?$/,
                            message: ''
                        }
                    ]}>
                    <InputNumber min={0} placeholder={intl.formatMessage({ id: "INTEREST_RATE" })} />
                </Form.Item>

                <Form.Item name="tenure" label={<FormattedMessage id="FINANCING_TENURE" />} required>
                    <Select>
                        {[...TenureDropdownHelper(3, 36)]}
                    </Select>
                </Form.Item>

                <Form.Item className="form-button">
                    <Button type="primary" htmlType="submit" onClick={() => setShowPayment(false)}>
                        <FormattedMessage id="CALCULATE" />
                    </Button>
                </Form.Item>
            </Form>
            {
                showPayment &&
                <div className="monthly-payment">
                    <div className="font-subtitle1"><FormattedMessage id="MONTHLY_PAYMENT" /></div>
                    <div className="result">{monthlyPayment}</div>
                </div>
            }
        </Modal>
    )
}

export default LoanCalculator;