import { FormattedMessage } from "react-intl";
import ideaImage from "../../../assets/images/idea.svg";
import careImage from "../../../assets/images/care.svg";
import bestImage from "../../../assets/images/best.svg";
import fastImage from "../../../assets/images/fast-time.svg";
import "./AboutUsContainer.less";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { SET_CURRENT_PATH, useLayout } from "../../../hooks/layout/LayoutContext";

const AboutUsContainer = () => {
    const navigate = useNavigate();
    const { layoutDispatch } = useLayout();

    const redirect = (path: string, redirect: boolean = true) => {
        layoutDispatch({ type: SET_CURRENT_PATH, payload: path });
        if (redirect) {
            navigate(path);
        }
    }

    return (
        <div className="content-container home-about-us-container">
            <div className="home-about-us-content">
                <div className="home-about-us-title header-1">
                    <FormattedMessage id="WHY_CHOOSE_US" />
                </div>
                <div className="font-body-2">
                    <FormattedMessage id="ABOUT_US_DESC" />
                </div>
                <div className="home-about-us-button">
                    <Button onClick={() => redirect("/about-us")}>
                        <FormattedMessage id="ABOUT_US" />
                    </Button>
                    <Button onClick={() => redirect("/contact-us")}>
                        <FormattedMessage id="CONTACT_US" />
                    </Button>
                </div>
            </div>
            {window.innerWidth < 576 ? null :
                <div className="home-about-us-subcontent">
                    <div className="home-about-us-subcontent-details">
                        <div>
                            <img src={ideaImage} alt="careImage" />
                            <div className="home-about-us-subtitle">
                                <FormattedMessage id="PRO" />
                            </div>
                            <div>
                                <FormattedMessage id="PRO_DESC" />
                            </div>
                        </div>
                        <div>
                            <img src={bestImage} alt="careImage" />
                            <div className="home-about-us-subtitle">
                                <FormattedMessage id="BEST" />
                            </div>
                            <div>
                                <FormattedMessage id="BEST_DESC" />
                            </div>
                        </div>
                    </div>
                    <div className="home-about-us-subcontent-details">
                        <div>
                            <img src={careImage} alt="careImage" />
                            <div className="home-about-us-subtitle">
                                <FormattedMessage id="CARE" />
                            </div>
                            <div>
                                <FormattedMessage id="CARE_DESC" />
                            </div>
                        </div>
                        <div>
                            <img src={fastImage} alt="careImage" />
                            <div className="home-about-us-subtitle">
                                <FormattedMessage id="FAST" />
                            </div>
                            <div>
                                <FormattedMessage id="FAST_DESC" />
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div>
    );
}

export default AboutUsContainer;