import { Button, Col, Form, Row, Upload } from "antd";
import { useEffect, useState } from "react";
import { UploadOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from "react-intl";
import { DELETE_UPLOADED_LOAN_DOCUMENTS, GET_LOAN_APPLICATION_BY_ID, UPLOAD_LOAN_DOCUMENTS, APPLY_LOAN_COMPLETE, LOAN_CANCEL } from "../Financing.gql";
import { useMutation, useQuery } from "@apollo/client";
import useNotification from "../../../hooks/layout/useNotification";
import { useNavigate } from "react-router-dom";
import "../Financing.less";
import { LoanApplicationCancelModal } from "../FinancingComponents";
interface Props {
    form: any;
}

const FinancingPersonalUploadForm = ({ form }: Props) => {
    const intl = useIntl();
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const [idCardFileList, setIdCardFileList] = useState<any[]>([]);
    const [paySlipFileList, setPaySlipFileList] = useState<any[]>([]);
    const [kwspFileList, setKwspFileList] = useState<any[]>([]);
    const [utilitiesFileList, setUtilitiesFileList] = useState<any[]>([]);
    const [bankFileList, setBankFileList] = useState<any[]>([]);
    const navigate = useNavigate();
    const [loanApplicationInfo, setloanApplicationInfo] = useState<any>();
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    const { data } = useQuery(GET_LOAN_APPLICATION_BY_ID, { variables: { id: loanApplicationInfo?.applicationId || '' }, fetchPolicy: 'cache-and-network' });
    const [uploadDocuments, { data: uploadData, error: uploadError }] = useMutation(UPLOAD_LOAN_DOCUMENTS,
        { awaitRefetchQueries: true, refetchQueries: [{ query: GET_LOAN_APPLICATION_BY_ID, variables: { id: loanApplicationInfo?.applicationId, fetchPolicy: 'cache-and-network' } },], errorPolicy: 'all' });
    const [applyComplete, { data: applyCompleteData }] = useMutation(APPLY_LOAN_COMPLETE);
    const [cancelLoan, { data: cancelLoanData, error: cancelLoanError }] = useMutation(LOAN_CANCEL);
    const [deleteUploadedDocuments, { data: deleteUploadedData, error: deleteUploadedError }] = useMutation(DELETE_UPLOADED_LOAN_DOCUMENTS);

    useEffect(() => {
        if (data) {
            const LoanData = data && data?.loanApplicationById?.documents;
            if (LoanData) {
                setIdCardFileList(LoanData['IDENTITY_CARD'] ? LoanData['IDENTITY_CARD'].map((m: any, index: any) => { return { name: idCardFileList[index]?.name, status: 'done', url: m } }) : []);
                setPaySlipFileList(LoanData['PAY_SLIP'] ? LoanData['PAY_SLIP'].map((m: any, index: any) => { return { name: paySlipFileList[index]?.name, status: 'done', url: m } }) : []);
                setKwspFileList(LoanData['KWSP_STATEMENT'] ? [{ name: kwspFileList[0]?.name, status: 'done', url: LoanData['KWSP_STATEMENT'][0] }] : []);
                setUtilitiesFileList(LoanData['UTILITIES_BILL'] ? LoanData['UTILITIES_BILL'].map((m: any, index: any) => { return { name: utilitiesFileList[index]?.name, status: 'done', url: m } }) : []);
                setBankFileList(LoanData['BANK_STATEMENT'] ? LoanData['BANK_STATEMENT'].map((m: any, index: any) => { return { name: bankFileList[index]?.name, status: 'done', url: m } }) : []);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        const loanApplicationData = localStorage.getItem("loanApplication");
        setloanApplicationInfo(loanApplicationData ? JSON.parse(loanApplicationData) : {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (uploadError) {
            setErrorNotification(intl.formatMessage({ id: 'UPLOAD_UNSUCCESSFUL' }));
        } else if (uploadData) {
            setSuccessNotification(intl.formatMessage({ id: 'UPLOAD_SUCCESSFUL' }));
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadData, uploadError]);

    useEffect(() => {
        if (cancelLoanError) {
            if (cancelLoanError.message?.indexOf('DATA_NOT_EXISTED') > -1)
                setErrorNotification(intl.formatMessage({ id: 'CANCEL_APPLICATION_FAIL' }));
            if (cancelLoanError.message?.indexOf('INVALID_INFO') > -1)
                setErrorNotification(intl.formatMessage({ id: 'CANCEL_APPLICATION_FAIL' }));

        } else if (cancelLoanData) {
            setSuccessNotification(intl.formatMessage({ id: 'CANCEL_LOAN_SUCCESS' }));
            navigate({
                pathname: "/financing/completion",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cancelLoanData, cancelLoanError]);

    useEffect(() => {
        if (deleteUploadedError) {
            setErrorNotification(intl.formatMessage({ id: 'DELETE_UPLOADED_UNSUCCESSFUL' }));
        } else if (deleteUploadedData) {
            // setData(deleteUploadedData.deleteUploadedProperty);
            setSuccessNotification(intl.formatMessage({ id: 'DELETE_UPLOADED_SUCCESSFUL' }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteUploadedData, deleteUploadedError]);

    useEffect(() => {
        if (applyCompleteData && applyCompleteData?.applyLoanComplete?.status === "SUCCESS") {
            navigate({
                pathname: "/financing/completion",
                search: "?type=success"
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applyCompleteData])

    const upload = (uploadFile: File, fileType: string) => {
        const variables: any = {
            uploadLoanInput: {
                loanId: loanApplicationInfo?.applicationId,
                uploadCategory: fileType,
                fileUpload: uploadFile ?? new File([], '', { type: 'text-plain' }),
            },
        }
        uploadDocuments({
            variables
        });
    }

    const handleUpload = async (e: any, type: string) => {
        if (e.file instanceof File) {
            upload(e.file, type);
        }
        return e && e.fileList;
    };

    const cancelApplication = () => {
        const variables: any = {
            id: loanApplicationInfo?.applicationId
        }
        cancelLoan({ variables })
    }

    const handleCancel = () => {
        setModalVisible(true);
    }

    const checkFileType = (e: any) => {
        if (e.type === 'image/jpeg' || e.type === 'image/jpg' || e.type === 'image/png' || e.type === 'application/pdf') {
            return false;
        }

        setErrorNotification(intl.formatMessage({ id: 'FORM_ACCEPTED_FILE_FORMAT' }, {
            0: intl.formatMessage({ id: "FORM_IMG_FORMAT" }),
        }));
        return Upload.LIST_IGNORE;
    }

    const handleRemoveloanUpload = async (e: any, type: string) => {
        let variables: any = {
            loanId: loanApplicationInfo?.applicationId,
            deleteCategory: type,
            deleteUrl: e.url,
        };
        deleteUploadedDocuments({ variables });
    }

    const onFinish = () => {
        let variables: any = {
            id: loanApplicationInfo?.applicationId,
        }
        if (idCardFileList.length > 0 && paySlipFileList.length > 0 && kwspFileList.length > 0 && utilitiesFileList.length > 0 && bankFileList.length > 0) {
            applyComplete({
                variables,
            });
        }
    }

    return (
        <>
            <div className="financing-form-container">
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={[32, 0]}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                            <FormattedMessage id="UPLOAD_DOCUMENTS" />
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <Form.Item getValueFromEvent={e => handleUpload(e, "IdentityCard")} name="IdentityCard" label={intl.formatMessage({ id: "FORM_TITLE_IC" })}
                                rules={[
                                    { required: true, message: intl.formatMessage({ id: 'FORM_IC_DOC_REQUIRED' }) }]}
                            >
                                <Upload name="idCardFileList" maxCount={2}
                                    fileList={idCardFileList}
                                    onChange={(e: any) => {
                                        setIdCardFileList(e.fileList)
                                    }}
                                    onRemove={e => handleRemoveloanUpload(e, "IdentityCard")}
                                    beforeUpload={checkFileType} showUploadList={{ showRemoveIcon: true }}
                                >
                                    <Button disabled={idCardFileList.length >= 2} icon={<UploadOutlined />}><FormattedMessage id="UPLOAD" /></Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <Form.Item getValueFromEvent={e => handleUpload(e, "PaySlip")} name="PaySlip" label={intl.formatMessage({ id: "FORM_TITLE_PAYSLIP" })}
                                rules={[
                                    { required: true, message: intl.formatMessage({ id: 'FORM_PAYSLIP_DOC_REQUIRED' }) }]}
                            >
                                <Upload name="paySlipFileList" maxCount={3}
                                    fileList={paySlipFileList}
                                    onChange={(e: any) => {
                                        setPaySlipFileList(e.fileList)
                                    }}
                                    onRemove={e => handleRemoveloanUpload(e, "PaySlip")}
                                    beforeUpload={checkFileType} showUploadList={{ showRemoveIcon: true }}
                                >
                                    <Button disabled={paySlipFileList.length >= 3} icon={<UploadOutlined />}><FormattedMessage id="UPLOAD" /></Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <Form.Item getValueFromEvent={e => handleUpload(e, "KwspStatement")} name="KwspStatement" label={intl.formatMessage({ id: "FORM_TITLE_KWSP" })}
                                rules={[
                                    { required: true, message: intl.formatMessage({ id: 'FORM_KWSP_DOC_REQUIRED' }) }]}
                            >
                                <Upload name="kwspFileList" maxCount={1}
                                    fileList={kwspFileList}
                                    onChange={(e: any) => {
                                        setKwspFileList(e.fileList)
                                    }}
                                    onRemove={e => handleRemoveloanUpload(e, "KwspStatement")}
                                    beforeUpload={checkFileType} showUploadList={{ showRemoveIcon: true }}
                                >
                                    <Button disabled={kwspFileList.length >= 1} icon={<UploadOutlined />}><FormattedMessage id="UPLOAD" /></Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <Form.Item getValueFromEvent={e => handleUpload(e, "UtilitiesBill")} name="UtilitiesBill" label={intl.formatMessage({ id: "FORM_TITLE_UTILITIES" })}
                                rules={[
                                    { required: true, message: intl.formatMessage({ id: 'FORM_UTILITIES_DOC_REQUIRED' }) }]}
                            >
                                <Upload name="utilitiesFileList" maxCount={2}
                                    fileList={utilitiesFileList}
                                    onChange={(e: any) => {
                                        setUtilitiesFileList(e.fileList)
                                    }}
                                    onRemove={e => handleRemoveloanUpload(e, "UtilitiesBill")}
                                    beforeUpload={checkFileType} showUploadList={{ showRemoveIcon: true }}
                                >
                                    <Button disabled={utilitiesFileList.length >= 2} icon={<UploadOutlined />}><FormattedMessage id="UPLOAD" /></Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <Form.Item getValueFromEvent={e => handleUpload(e, "BankStatement")} name="BankStatement" label={intl.formatMessage({ id: "FORM_TITLE_BANK" })}
                                rules={[
                                    { required: true, message: intl.formatMessage({ id: 'FORM_BANK_DOC_REQUIRED' }) }]}
                            >
                                <Upload name="bankFileList" maxCount={3}
                                    fileList={bankFileList}
                                    onChange={(e: any) => {
                                        setBankFileList(e.fileList)
                                    }}
                                    onRemove={e => handleRemoveloanUpload(e, "BankStatement")}
                                    beforeUpload={checkFileType} showUploadList={{ showRemoveIcon: true }}
                                >
                                    <Button disabled={bankFileList.length >= 3} icon={<UploadOutlined />}><FormattedMessage id="UPLOAD" /></Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                        <Form.Item className="form-button">
                            <Button type="default" onClick={handleCancel}>
                                {intl.formatMessage({ id: 'FORM_BUTTON_CANCEL_APPLICATION' })}
                            </Button>
                            <Button type="primary" htmlType="submit"
                                disabled={idCardFileList.length === 0 || paySlipFileList.length === 0 || kwspFileList.length === 0 || utilitiesFileList.length === 0 || bankFileList.length === 0}
                            >
                                {intl.formatMessage({ id: 'FORM_BUTTON_SUBMIT' })}
                            </Button>
                        </Form.Item>
                    </Col>
                </Form>
            </div>
            <LoanApplicationCancelModal
                title={intl.formatMessage({ id: "FORM_CANCEL_APPLICATION" })}
                visible={modalVisible}
                setVisible={setModalVisible}
                cancelApplication={cancelApplication}
            />
        </>
    )
}

export default FinancingPersonalUploadForm;