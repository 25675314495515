import { useMutation } from "@apollo/client";
import { Alert, Button, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { setTokenCookie } from "../../shared/helpers/set-token-cookies.helper";
import PhoneInput from "../../shared/widgets/phone-input/PhoneInput";
import { PHONE_FIRST_SIGNIN, FIRST_LOGIN_REQUEST_CODE } from "./auth.gql";
import CodeRequestButton from "./CodeRequestButton";
import { ReactComponent as TagLogo } from '../../assets/images/TAC.svg';
import { useNavigate } from "react-router-dom";
import useNotification from "../../hooks/layout/useNotification";

interface Props {
    next?: () => void;
}

const FirstLoginForm = ({ next }: Props) => {
    const [phoneFirstSignIn, { data: phoneFirstSigninData, loading: phoneFirstSigninLoading, error: phoneFirstSigninError }]
        = useMutation(PHONE_FIRST_SIGNIN, { fetchPolicy: "no-cache", errorPolicy: "all" });
    const [requestCode, { data: requestCodeData, error: requestCodeError }] = useMutation(FIRST_LOGIN_REQUEST_CODE, { fetchPolicy: "no-cache", errorPolicy: "all" });
    const intl = useIntl();
    const navigate = useNavigate();
    const { setErrorNotification } = useNotification();
    const [form] = Form.useForm();
    const [phone, setPhone] = useState<string>();
    const [countryId, setCountryId] = useState<string>('MY');
    const [error, setError] = useState<string>();
    const [otpCode, setOtpCode] = useState<string>();

    useEffect(() => {
        if ((phoneFirstSigninData && phoneFirstSigninData.phoneFirstSignIn)) {
            //set Login tokens
            const { a, r, sid } = phoneFirstSigninData.phoneFirstSignIn;
            if (a) setTokenCookie('scf-at', a);
            if (sid) setTokenCookie('scf-sid', sid);
            if (r) {
                setTokenCookie('scf-rt', r);
                if (next) next();
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phoneFirstSigninData]);

    useEffect(() => {
        if (requestCodeData && requestCodeData.firstLoginRequestCode && requestCodeData.firstLoginRequestCode.sid) {
            setOtpCode(requestCodeData.firstLoginRequestCode.sid);
        }
    }, [requestCodeData])


    useEffect(() => {
        if (phoneFirstSigninError || requestCodeError) {
            let errorMessage: string = '';
            if (phoneFirstSigninError?.message) {
                errorMessage = phoneFirstSigninError?.message;
            }
            else if (requestCodeError?.message) {
                errorMessage = requestCodeError?.message;
            }

            if (errorMessage.indexOf('INVALID_LOGIN') > -1) {
                setError('AUTH_ERROR_INVALID_LOGIN');
            } else if (errorMessage.indexOf('ACCOUNT_NOT_EXISTED') > -1) {
                setError('AUTH_ERROR_ACCOUNT_NOT_EXISTED');
            } else if (errorMessage.indexOf('INVALID_CODE') > -1) {
                setError('AUTH_ERROR_INVALID_CODE');
            } else if (errorMessage.indexOf('ACCOUNT_EXISTED') > -1) {
                setErrorNotification(intl.formatMessage({ id: 'AUTH_ERROR_ACCOUNT_EXISTED' }));
                navigate("/auth");
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phoneFirstSigninError, requestCodeError])

    const requestStatus = () => {
        setError(undefined);
        requestCode({
            variables: {
                phoneNumber: phone,
            }
        })
    }

    const onValuesChange = ({ phoneNumber }: any) => {
        if (phoneNumber) {
            setPhone(phoneNumber);
        }
    }

    const onFinish = (values: any) => {
        phoneFirstSignIn({
            variables: {
                phoneNumber: phone,
                code: values.code
            }
        })
    };

    const selectCountryId = (countryId: string) => {
        setCountryId(countryId);
    }

    return (<Form
        name="form"
        form={form}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
    >
        {error && <Alert
            className="auth-error"
            message={<FormattedMessage id={error} />}
            type="error"
            showIcon
        />}
        <Form.Item
            name="phoneNumber"
            rules={[
                { required: true, message: intl.formatMessage({ id: 'LOGIN_USERNAME_REQUIRED' }) },
                { min: 8, message: intl.formatMessage({ id: 'LOGIN_USERNAME_VALIDATE' }) }]}>
            <PhoneInput selectedCountryId={countryId} selectCountryId={selectCountryId} />
        </Form.Item>
        {
            process.env.REACT_APP_SHOW_OTP === '1' && otpCode &&
            <span>OTP: {otpCode}</span>
        }
        <div className="sms-same-row-container">
            <Form.Item
                name="code"
                rules={[
                    { required: true, message: intl.formatMessage({ id: 'LOGIN_CODE_REQUIRED' }) },
                    { min: 6, message: intl.formatMessage({ id: 'AUTH_INVALID_CODE' }) }
                ]}>
                <Input maxLength={6} placeholder={intl.formatMessage({ id: 'PHONE_VERIFICATION_INPUT_PLACEHOLDER' })} prefix={<TagLogo />} />
            </Form.Item>
            <CodeRequestButton
                phoneNumber={phone}
                hasError={phoneFirstSigninError ? true : false}
                requestStatus={requestStatus} setError={setError} />
        </div>
        <Form.Item className="auth-button">
            <Button block type="primary" htmlType="submit" loading={phoneFirstSigninLoading}>
                <FormattedMessage id={'GENERAL_NEXT'} />
            </Button>
        </Form.Item>
    </Form>)
}

export default FirstLoginForm;