import { useMutation } from "@apollo/client";
import { Alert, Button, Col, Form, Input, Row } from "antd";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { APPLY_LOAN, GET_CODE } from "./Financing.gql";
import { VerificationType } from "./Financing.enum";
import "./Financing.less";

interface Props {
    form: any;
    currentStep: number;
    setCurrentStep: Dispatch<SetStateAction<number>>;
}

const FinancingOtpForm = ({ form, currentStep, setCurrentStep }: Props) => {
    const intl = useIntl();
    const [error, setError] = useState<string>();
    const [loanApplicationInfo, setloanApplicationInfo] = useState<any>();
    const [loanApplicationVariables, setloanApplicationVariables] = useState<any>();
    const [requestPin] = useMutation(GET_CODE, { fetchPolicy: "no-cache", errorPolicy: "all" });
    const [applyLoan, { data: applyLoanData, error: applyLoanError }] = useMutation(APPLY_LOAN,
        { errorPolicy: 'all' });

    useEffect(() => {
        const loanApplicationData: any = JSON.parse(localStorage.getItem("loanApplication") || "") || {};
        setloanApplicationInfo(loanApplicationData);
        const _loanApplicationVariables: any = JSON.parse(localStorage.getItem("loanDataVariables") || "") || {};
        setloanApplicationVariables(_loanApplicationVariables);
        if (loanApplicationData) {
            requestPin({
                variables: {
                    verificationType: VerificationType.PHONE.toString(),
                    phone: loanApplicationData?.phoneNumber,
                }
            });
        }
        return () => {
            localStorage.removeItem("loanDataVariables");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (applyLoanData) {
            if (applyLoanData.applyLoan?.status === "CODE_NOT_MATCH") {
                setError("OTP_FAIL");
            } else {
                const loanApplication = {
                    ...loanApplicationInfo,
                    applicationId: applyLoanData.applyLoan.id,
                    refId: applyLoanData.applyLoan.a,
                };
                localStorage.setItem("loanApplication", JSON.stringify(loanApplication));
                setCurrentStep(currentStep + 1);
            }
        } else if (applyLoanError) {
            setErrorNotification(intl.formatMessage({ id: 'APPLY_LOAN_ERROR' }))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applyLoanData, applyLoanError]);

    const handlePrevious = () => {
        setCurrentStep(currentStep - 1);
    }

    const onFinish = (fieldsValue: any) => {
        const variables: any = {
            applyLoanInput: {
                ...loanApplicationVariables,
                otpCode: fieldsValue.code,
            }
        }
        applyLoan({
            variables
        });
    }

    const handleResendOtp = () => {
        requestPin({
            variables: {
                verificationType: VerificationType.PHONE.toString(),
                phone: loanApplicationInfo?.phoneNumber,
            }
        });
    }

    return (
        <div className="financing-form-container">
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
            >
                <Row gutter={[32, 0]}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12, offset: 6 }} lg={{ span: 12, offset: 6 }}>
                        {error && (
                            <Alert className="otp-error" message={<FormattedMessage id={error} />} type="error" showIcon />
                        )}
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12, offset: 6 }} lg={{ span: 12, offset: 6 }}>
                        <div className="form-title">
                            <span><FormattedMessage id="OTP_MESSAGE" /> {loanApplicationInfo?.phoneNumber}</span>
                        </div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12, offset: 6 }} lg={{ span: 12, offset: 6 }}>
                        <Form.Item name="code"
                            rules={[
                                { required: true, message: intl.formatMessage({ id: 'FORM_OTP_REQUIRED' }) }]}
                        >
                            <Input maxLength={6} placeholder={intl.formatMessage({ id: "FORM_OTP_PLACEHOLDER" })} />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12, offset: 6 }} lg={{ span: 12, offset: 6 }}>
                        <div className="form-title">
                            <FormattedMessage id="OTP_NOT_RECEIVE" />
                            <Button type="link" onClick={handleResendOtp}><FormattedMessage id="RESEND_OTP" /></Button>
                        </div>
                    </Col>
                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 8, offset: 4 }} lg={{ span: 8, offset: 4 }}>
                        <Form.Item className="form-button">
                            <Button type="default" onClick={handlePrevious} block>
                                {intl.formatMessage({ id: 'BACK' })}
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                        <Form.Item className="form-button">
                            <Button type="primary" htmlType="submit" block>
                                {intl.formatMessage({ id: 'CONTINUE' })}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default FinancingOtpForm;

function setErrorNotification(arg0: string) {
    throw new Error("Function not implemented.");
}
