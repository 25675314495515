import { Button, Col, Form, Row, Upload } from "antd";
import { useEffect, useState } from "react";
import { UploadOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from "react-intl";
import { APPLY_LOAN_COMPLETE, DELETE_UPLOADED_LOAN_DOCUMENTS, GET_LOAN_APPLICATION_BY_ID, LOAN_CANCEL, UPLOAD_LOAN_DOCUMENTS } from "../Financing.gql";
import { useMutation, useQuery } from "@apollo/client";
import useNotification from "../../../hooks/layout/useNotification";
import { useNavigate } from "react-router-dom";
import "../Financing.less";
import { LoanApplicationCancelModal } from "../FinancingComponents";
interface Props {
    form: any;
}

const FinancingBusinessUploadSoleForm = ({ form }: Props) => {
    const intl = useIntl();
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const [spIdentityCardList, setSpIdentityCard] = useState<any[]>([]);
    const [spCompanyBankStatementList, setSpCompanyBankStatement] = useState<any[]>([]);
    const [spSSMStatementList, setSpSSMStatementList] = useState<any[]>([]);
    const [spPaySlipList, setSpPaySlipList] = useState<any[]>([]);
    const [spPersonalBankStatementList, setSpPersonalBankStatementList] = useState<any[]>([]);
    const [spBankLoanStatementList, setSpBankLoanStatement] = useState<any[]>([]);
    const [spPersonalIncomeTaxList, setSpPersonalIncomeTaxList] = useState<any[]>([]);
    const [spKwspStatementList, setSpKwspStatementList] = useState<any[]>([]);
    const [cancelLoan, { data: cancelLoanData, error: cancelLoanError }] = useMutation(LOAN_CANCEL);
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    const navigate = useNavigate();
    const [loanApplicationInfo, setloanApplicationInfo] = useState<any>();

    const { data } = useQuery(GET_LOAN_APPLICATION_BY_ID, { variables: { id: loanApplicationInfo?.applicationId || '' }, fetchPolicy: 'cache-and-network' });

    const [uploadDocuments, { data: uploadData, error: uploadError }] = useMutation(UPLOAD_LOAN_DOCUMENTS,
        { awaitRefetchQueries: true, refetchQueries: [{ query: GET_LOAN_APPLICATION_BY_ID, variables: { id: loanApplicationInfo?.applicationId, fetchPolicy: 'cache-and-network' } },], errorPolicy: 'all' });
    const [applyComplete, { data: applyCompleteData }] = useMutation(APPLY_LOAN_COMPLETE);
    const [deleteUploadedDocuments, { data: deleteUploadedData, error: deleteUploadedError }] = useMutation(DELETE_UPLOADED_LOAN_DOCUMENTS);

    useEffect(() => {
        if (data) {
            const LoanData = data && data?.loanApplicationById?.documents;
            if (LoanData) {
                setSpIdentityCard(LoanData['SP_IDENTITY_CARD'] ? LoanData['SP_IDENTITY_CARD'].map((m: any, index: any) => { return { name: spIdentityCardList[index]?.name, status: 'done', url: m } }) : []);
                setSpCompanyBankStatement(LoanData['SP_COMPANY_BANK_STATEMENT'] ? LoanData['SP_COMPANY_BANK_STATEMENT'].map((m: any, index: any) => { return { name: spCompanyBankStatementList[index]?.name, status: 'done', url: m } }) : []);
                setSpSSMStatementList(LoanData['SP_SSM_STATEMENT'] ? LoanData['SP_SSM_STATEMENT'].map((m: any, index: any) => { return { name: spSSMStatementList[index]?.name, status: 'done', url: m } }) : []);
                setSpPaySlipList(LoanData['SP_PAY_SLIP'] ? LoanData['SP_PAY_SLIP'].map((m: any, index: any) => { return { name: spPaySlipList[index]?.name, status: 'done', url: m } }) : []);
                setSpPersonalBankStatementList(LoanData['SP_PERSONAL_BANK_STATEMENT'] ? LoanData['SP_PERSONAL_BANK_STATEMENT'].map((m: any, index: any) => { return { name: spPersonalBankStatementList[index]?.name, status: 'done', url: m } }) : []);
                setSpBankLoanStatement(LoanData['SP_BANK_LOAN_STATEMENT'] ? LoanData['SP_BANK_LOAN_STATEMENT'].map((m: any, index: any) => { return { name: spBankLoanStatementList[index]?.name, status: 'done', url: m } }) : []);
                setSpPersonalIncomeTaxList(LoanData['SP_PERSONAL_INCOME_TAX'] ? LoanData['SP_PERSONAL_INCOME_TAX'].map((m: any, index: any) => { return { name: spPersonalIncomeTaxList[index]?.name, status: 'done', url: m } }) : []);
                setSpKwspStatementList(LoanData['SP_KWSP_STATEMENT'] ? [{ name: spKwspStatementList[0]?.name, status: 'done', url: LoanData['SP_KWSP_STATEMENT'][0] }] : []);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        const loanApplicationData = localStorage.getItem("loanApplication");
        setloanApplicationInfo(loanApplicationData ? JSON.parse(loanApplicationData) : {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (cancelLoanError) {
            if (cancelLoanError.message?.indexOf('DATA_NOT_EXISTED') > -1)
                setErrorNotification(intl.formatMessage({ id: 'CANCEL_APPLICATION_FAIL' }));
            if (cancelLoanError.message?.indexOf('INVALID_INFO') > -1)
                setErrorNotification(intl.formatMessage({ id: 'CANCEL_APPLICATION_FAIL' }));

        } else if (cancelLoanData) {
            setSuccessNotification(intl.formatMessage({ id: 'CANCEL_LOAN_SUCCESS' }));
            navigate({
                pathname: "/financing/completion",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cancelLoanData, cancelLoanError]);

    useEffect(() => {
        if (uploadError) {
            setErrorNotification(intl.formatMessage({ id: 'UPLOAD_UNSUCCESSFUL' }));
        } else if (uploadData) {
            setSuccessNotification(intl.formatMessage({ id: 'UPLOAD_SUCCESSFUL' }));
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadData, uploadError]);

    useEffect(() => {
        if (deleteUploadedError) {
            setErrorNotification(intl.formatMessage({ id: 'DELETE_UPLOADED_UNSUCCESSFUL' }));
        } else if (deleteUploadedData) {
            // setData(deleteUploadedData.deleteUploadedProperty);
            setSuccessNotification(intl.formatMessage({ id: 'DELETE_UPLOADED_SUCCESSFUL' }));
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteUploadedData, deleteUploadedError]);

    useEffect(() => {
        if (applyCompleteData && applyCompleteData?.applyLoanComplete?.status === "SUCCESS") {
            navigate({
                pathname: "/financing/completion",
                search: "?type=success"
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applyCompleteData])

    const upload = (uploadFile: File, fileType: string) => {
        const variables: any = {
            uploadLoanInput: {
                loanId: loanApplicationInfo?.applicationId,
                uploadCategory: fileType,
                fileUpload: uploadFile ?? new File([], '', { type: 'text-plain' }),
            },
        }
        uploadDocuments({
            variables
        });
    }

    const handleUpload = async (e: any, type: string) => {
        if (e.file instanceof File) {
            upload(e.file, type);
        }
        return e && e.fileList;
    };

    const cancelApplication = () => {
        const variables: any = {
            id: loanApplicationInfo?.applicationId
        }
        cancelLoan({ variables })
    }

    const handleCancel = () => {
        setModalVisible(true);
    }

    const checkFileType = (e: any) => {
        if (e.type === 'image/jpeg' || e.type === 'image/jpg' || e.type === 'image/png' || e.type === 'application/pdf') {
            return false;
        }

        setErrorNotification(intl.formatMessage({ id: 'FORM_ACCEPTED_FILE_FORMAT' }, {
            0: intl.formatMessage({ id: "FORM_IMG_FORMAT" }),
        }));
        return Upload.LIST_IGNORE;
    }

    const handleRemoveloanUpload = async (e: any, type: string) => {
        let variables: any = {
            loanId: loanApplicationInfo?.applicationId,
            deleteCategory: type,
            deleteUrl: e.url,
        };
        deleteUploadedDocuments({ variables });
    }

    const onFinish = () => {
        let variables: any = {
            id: loanApplicationInfo?.applicationId,
        }
        if (spIdentityCardList.length > 0 && spCompanyBankStatementList.length > 0 && spSSMStatementList.length > 0 && spPersonalBankStatementList.length > 0 &&
            spPersonalBankStatementList.length > 0 && spBankLoanStatementList.length > 0 && spPersonalIncomeTaxList.length > 0) {
            applyComplete({
                variables,
            });
        }
    }

    return (
        <>
            <div className="financing-form-container">
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={[32, 0]}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                            <FormattedMessage id="UPLOAD_DOCUMENTS_SOLE_PROPRIETOR" />
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <Form.Item getValueFromEvent={e => handleUpload(e, "SPIdentityCard")} name="SPIdentityCard" label={intl.formatMessage({ id: "FORM_SP_IC" })}
                                rules={[
                                    { required: true, message: intl.formatMessage({ id: 'FORM_SP_IC_REQUIRED' }) }]}
                            >
                                <Upload name="spIdentityCardList" maxCount={10}
                                    fileList={spIdentityCardList}
                                    onChange={(e: any) => {
                                        setSpIdentityCard(e.fileList)
                                    }}
                                    onRemove={e => handleRemoveloanUpload(e, "SPIdentityCard")}
                                    beforeUpload={checkFileType} showUploadList={{ showRemoveIcon: true }}
                                >
                                    <Button disabled={spIdentityCardList.length >= 10} icon={<UploadOutlined />}><FormattedMessage id="UPLOAD" /></Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <Form.Item getValueFromEvent={e => handleUpload(e, "SPCompanyBankStatement")} name="SPCompanyBankStatement" label={intl.formatMessage({ id: "FORM_SP_BANK_STATEMENT" })}
                                rules={[
                                    { required: true, message: intl.formatMessage({ id: 'FORM_SP_BANK_STATEMENT_REQUIRED' }) }]}
                            >
                                <Upload name="spCompanyBankStatementList" maxCount={3}
                                    fileList={spCompanyBankStatementList}
                                    onChange={(e: any) => {
                                        setSpCompanyBankStatement(e.fileList)
                                    }}
                                    onRemove={e => handleRemoveloanUpload(e, "SPCompanyBankStatement")}
                                    beforeUpload={checkFileType} showUploadList={{ showRemoveIcon: true }}
                                >
                                    <Button disabled={spCompanyBankStatementList.length >= 3} icon={<UploadOutlined />}><FormattedMessage id="UPLOAD" /></Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <Form.Item getValueFromEvent={e => handleUpload(e, "SPSSMStatement")} name="SPSSMStatement" label={intl.formatMessage({ id: "FORM_SP_SSM" })}
                                rules={[
                                    { required: true, message: intl.formatMessage({ id: 'FORM_SP_SSM_REQUIRED' }) }]}
                            >
                                <Upload name="spSSMStatementList" maxCount={3}
                                    fileList={spSSMStatementList}
                                    onChange={(e: any) => {
                                        setSpSSMStatementList(e.fileList)
                                    }}
                                    onRemove={e => handleRemoveloanUpload(e, "SPSSMStatement")}
                                    beforeUpload={checkFileType} showUploadList={{ showRemoveIcon: true }}
                                >
                                    <Button disabled={spSSMStatementList.length >= 3} icon={<UploadOutlined />}><FormattedMessage id="UPLOAD" /></Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <Form.Item getValueFromEvent={e => handleUpload(e, "SPPaySlip")} name="SPPaySlip" label={intl.formatMessage({ id: "FORM_SP_PAY_SLIP" })}
                            >
                                <Upload name="spPaySlipList" maxCount={3}
                                    fileList={spPaySlipList}
                                    onChange={(e: any) => {
                                        setSpPaySlipList(e.fileList)
                                    }}
                                    onRemove={e => handleRemoveloanUpload(e, "SPPaySlip")}
                                    beforeUpload={checkFileType} showUploadList={{ showRemoveIcon: true }}
                                >
                                    <Button disabled={spPaySlipList.length >= 3} icon={<UploadOutlined />}><FormattedMessage id="UPLOAD" /></Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <Form.Item getValueFromEvent={e => handleUpload(e, "SPPersonalBankStatement")} name="SPPersonalBankStatement" label={intl.formatMessage({ id: "FORM_SP_BANK_PERSONAL_STATEMENT" })}
                                rules={[
                                    { required: true, message: intl.formatMessage({ id: 'FORM_SP_BANK_PERSONAL_STATEMENT_REQUIRED' }) }]}
                            >
                                <Upload name="spPersonalBankStatementList" maxCount={3}
                                    fileList={spPersonalBankStatementList}
                                    onChange={(e: any) => {
                                        setSpPersonalBankStatementList(e.fileList)
                                    }}
                                    onRemove={e => handleRemoveloanUpload(e, "SPPersonalBankStatement")}
                                    beforeUpload={checkFileType} showUploadList={{ showRemoveIcon: true }}
                                >
                                    <Button disabled={spPersonalBankStatementList.length >= 3} icon={<UploadOutlined />}><FormattedMessage id="UPLOAD" /></Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <Form.Item getValueFromEvent={e => handleUpload(e, "SPBankLoanStatement")} name="SPBankLoanStatement" label={intl.formatMessage({ id: "FORM_SP_BANK_LOAN_STATEMENT" })}
                                rules={[
                                    { required: true, message: intl.formatMessage({ id: 'FORM_SP_BANK_LOAN_STATEMENT_REQUIRED' }) }]}
                            >
                                <Upload name="spBankLoanStatementList" maxCount={3}
                                    fileList={spBankLoanStatementList}
                                    onChange={(e: any) => {
                                        setSpBankLoanStatement(e.fileList)
                                    }}
                                    onRemove={e => handleRemoveloanUpload(e, "SPBankLoanStatement")}
                                    beforeUpload={checkFileType} showUploadList={{ showRemoveIcon: true }}
                                >
                                    <Button disabled={spBankLoanStatementList.length >= 3} icon={<UploadOutlined />}><FormattedMessage id="UPLOAD" /></Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <Form.Item getValueFromEvent={e => handleUpload(e, "SPPersonalIncomeTax")} name="SPPersonalIncomeTax" label={intl.formatMessage({ id: "FORM_SP_INCOME_TAX" })}
                                rules={[
                                    { required: true, message: intl.formatMessage({ id: 'FORM_SP_INCOME_TAX_REQUIRED' }) }]}
                            >
                                <Upload name="spPersonalIncomeTaxList" maxCount={20}
                                    fileList={spPersonalIncomeTaxList}
                                    onChange={(e: any) => {
                                        setSpPersonalIncomeTaxList(e.fileList)
                                    }}
                                    onRemove={e => handleRemoveloanUpload(e, "SPPersonalIncomeTax")}
                                    beforeUpload={checkFileType} showUploadList={{ showRemoveIcon: true }}
                                >
                                    <Button disabled={spPersonalIncomeTaxList.length >= 20} icon={<UploadOutlined />}><FormattedMessage id="UPLOAD" /></Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <Form.Item getValueFromEvent={e => handleUpload(e, "SPKwspStatement")} name="SPKwspStatement" label={intl.formatMessage({ id: "FORM_SP_KWSP" })}
                            >
                                <Upload name="spKwspStatementList" maxCount={1}
                                    fileList={spKwspStatementList}
                                    onChange={(e: any) => {
                                        setSpKwspStatementList(e.fileList)
                                    }}
                                    onRemove={e => handleRemoveloanUpload(e, "SPKwspStatement")}
                                    beforeUpload={checkFileType} showUploadList={{ showRemoveIcon: true }}
                                >
                                    <Button disabled={spKwspStatementList.length >= 1} icon={<UploadOutlined />}><FormattedMessage id="UPLOAD" /></Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                        <Form.Item className="form-button">
                            <Button type="default" onClick={handleCancel}>
                                {intl.formatMessage({ id: 'FORM_BUTTON_CANCEL_APPLICATION' })}
                            </Button>
                            <Button type="primary" htmlType="submit"
                                disabled={spIdentityCardList.length === 0 || spCompanyBankStatementList.length === 0 || spSSMStatementList.length === 0 || spPersonalBankStatementList.length === 0 ||
                                    spPersonalBankStatementList.length === 0 || spBankLoanStatementList.length === 0 || spPersonalIncomeTaxList.length === 0}
                            >
                                {intl.formatMessage({ id: 'FORM_BUTTON_SUBMIT' })}
                            </Button>
                        </Form.Item>
                    </Col>
                </Form>
            </div>
            <LoanApplicationCancelModal
                title={intl.formatMessage({ id: "FORM_CANCEL_APPLICATION" })}
                visible={modalVisible}
                setVisible={setModalVisible}
                cancelApplication={cancelApplication}
            />
        </>
    )
}

export default FinancingBusinessUploadSoleForm;