import { gql } from "@apollo/client";

export const GET_USER_PROFILE = gql`
	query UserProfile {
		userProfile {
            id,
            fullName,
            userName,
            photoUrl,
            countryId,
            phone,
            email,
            gender,
            isActive,
            requiredPasswordChange
        }
	}`;


export const CREATE_PIN = gql`
mutation UserPinCreate($pinCode:String!){ 
	userPinCreate(pinCode:$pinCode){ 
		status, id 
	} 
}
`;

export const AFFIRM_PIN = gql`
mutation UserPinAffirm($pinCode:String!){ 
	userPinAffirm(pinCode:$pinCode){ 
		status, id 
	} 
}
`;
