import { Col, Form, Row, Steps } from "antd";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { PersonalFinancingCashFlowEvents, PersonalFinancingCashFlowNeeds, PersonalFinancingDocuments, PersonalFinancingRequirements } from "../FinancingComponents";
import FinancingForm from "../FinancingForm";
import FinancingOtpForm from "../FinancingOtpForm";
import "./FinancingPersonal.less";
import FinancingPersonalUploadForm from "./FinancingPersonalUploadForm";

const FinancingPersonal = () => {
    const [form] = Form.useForm();
    const [currentStep, setCurrentStep] = useState(0);
    const [, setMasterFormData] = useState<any>();
    const { Step } = Steps;

    const steps = [
        {
            title: "DETAILS",
            content: <FinancingForm form={form} currentStep={currentStep} setCurrentStep={setCurrentStep} isBusiness={false} setMasterFormData={setMasterFormData} />
        },
        {
            title: "OTP",
            content: <FinancingOtpForm form={form} currentStep={currentStep} setCurrentStep={setCurrentStep} />
        },
        {
            title: "DOCUMENTS",
            content: <FinancingPersonalUploadForm form={form} />,
        },
    ];

    return (
        <>
            <div className="personal-financing-banner">
                <div className="personal-financing-header">
                    <span className="header-1">
                        <FormattedMessage id="PERSONAL_LOAN" />
                    </span>
                    <span className="font-body-2">
                        <FormattedMessage id="EXTRA_CASH_NEEDS" />
                    </span>
                </div>
                <div className="financing-component-container">
                    <Row gutter={[36, 12]}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} className='financing-col'>
                            <PersonalFinancingCashFlowNeeds />
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} className='financing-col'>
                            <PersonalFinancingCashFlowEvents />
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="bg-gray-container ">
                <div className="content-container personal-financing-container">
                    <div className="personal-financing-content financing-container-content">
                        <Row gutter={[36, 12]}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 9, offset: 3 }} className='financing-col'>
                                <PersonalFinancingRequirements />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 11, offset: 1 }} className='financing-col'>
                                <PersonalFinancingDocuments />
                            </Col>
                        </Row>
                    </div>
                    <div className="bg-gray-container">
                        <div className="personal-header-container">
                            <div className="header-2"><FormattedMessage id="APPLY_PERSONAL_LOAN" /></div>
                            <div><FormattedMessage id="PERSONAL_LOAN_SUBTITLE" /></div>
                        </div>
                        <div className="personal-steps-container">
                            <Steps direction="horizontal" current={currentStep} size="small">
                                {steps.map(item => (
                                    <Step key={item.title} title={<FormattedMessage id={item.title} />} />
                                ))}
                            </Steps>
                            {steps[currentStep].content}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FinancingPersonal;