import AboutUsContainer from "./about-us-container/AboutUsContainer";
import ContactUsContainer from "../../shared/components/contact-us-container/ContactUsContainer";
import FinancingContainer from "./financing-container/FinancingContainer";
import "./Home.less";
import PinnedContainer from "./pinned-container/PinnedContainer";
import StatisticContainer from "./statistic-container/StatisticContainer";

const Home = () => {

    return (
        <>
            <div className="bg-white-container">
                <PinnedContainer />
            </div>
            <div className="bg-gray-container">
                <StatisticContainer />
            </div>
            <div className="bg-white-container">
                <AboutUsContainer />
            </div>
            <div className="bg-gray-container">
                <FinancingContainer />
            </div>
            <div className="bg-white-container">
                <ContactUsContainer />
            </div>
        </>
    );
};

export default Home;
