import './Auth.less';
import { FormattedMessage } from 'react-intl';
import { Typography, Button } from 'antd';
import LoginPasswordForm from './LoginPasswordForm';
import { Link } from 'react-router-dom';
const { Title } = Typography;

const Login = () => {

    return (
        <>
            <div className="auth-bg-container">
                <div className="auth-container">
                    <div className="cover-container">
                        <Title level={3} ><FormattedMessage id="AUTH_BUTTON_LOGIN" /></Title>
                        <div className="auth-content">
                            <LoginPasswordForm />
                            <div className="forgot-password-container">
                                <div>
                                    <Link to="/first-login">
                                        <Button type="link" size="small"><FormattedMessage id="AUTH_SIGNUP_LINK" /></Button>
                                    </Link>
                                </div>
                                <Link to="/forgot-password">
                                    <Button type="link" size="small"><FormattedMessage id="AUTH_FORGOT_PASSWORD" /></Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login