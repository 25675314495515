import { FormattedMessage, useIntl } from "react-intl";
import { useMutation } from "@apollo/client";
import { Button, Form, Input } from "antd";
import "./ContactUsContainer.less";
import { CREATE_ENQUIRY } from "../../../shared/gql/contact-us.gql";
import { useEffect } from "react";
import useSpinner from "../../../hooks/layout/useSpinner";
import useNotification from "../../../hooks/layout/useNotification";

const ContactUsForm = () => {
    const { setLoading } = useSpinner();
	const [form] = Form.useForm();
	const intl = useIntl();
	const { setSuccessNotification, setErrorNotification } = useNotification();
	const [createEnquiry, { data, loading, error }] = useMutation(CREATE_ENQUIRY, { errorPolicy: "all" });

	useEffect(() => {
		if (loading) {
			setLoading(true);
		} else {
			setLoading(false);
		}
		// eslint-disable-next-line
	}, [loading]);

	useEffect(() => {
		if (error) {
			setErrorNotification(intl.formatMessage({id: 'SEND_MESSAGE_FAILURE'}));
		} else if (data) {
			form.resetFields();
			setSuccessNotification(intl.formatMessage({id: 'SEND_MESSAGE_SUCCESSFUL'}));
		}
		// eslint-disable-next-line
	}, [data, error]);

	const onFinish = (values: any) => {
		const variables: any = {
            enquiryInput: values
        };
		createEnquiry({ variables });
	}

    return (
        <Form
            name="form"
            form={form}
            onFinish={onFinish}
        >
            <Form.Item name="name" label={<><FormattedMessage id="NAME" /></>} required className="need-require"
                rules={[
                    { required: true, message: intl.formatMessage({ id: 'NAME_REQUIRED' }) }
                ]}>
                <Input maxLength={255} placeholder={intl.formatMessage({ id: 'INPUT_NAME' })}/>
            </Form.Item>
            <Form.Item name="email" label={<><FormattedMessage id="EMAIL_ADDRESS" /></>} required className="need-require"
                rules={[
                    { required: true, message: intl.formatMessage({ id: 'EMAIL_REQUIRED' }) },
                    { type: "email", message: intl.formatMessage({ id: 'EMAIL_TYPE_INVALID' }) }
                ]}>
                <Input maxLength={255} placeholder={intl.formatMessage({ id: 'INPUT_EMAIL_ADDRESS' })} type="email"/>
            </Form.Item>
            <Form.Item name="phone" label={<><FormattedMessage id="PHONE" /></>} required className="need-require"
                rules={[
                    { required: true, message: intl.formatMessage({ id: 'PHONE_REQUIRED' }) }
                ]}>
                <Input maxLength={15} placeholder={intl.formatMessage({ id: 'INPUT_PHONE' })}/>
            </Form.Item>
            <Form.Item
                name="message"
                label={<><FormattedMessage id="MESSAGE" /></>}
                required className="need-require"
                rules={[
                    { required: true, message: intl.formatMessage({ id: 'MESSAGE_REQUIRED' }) },
                    { max: 2000 },
                ]}>
                <Input.TextArea rows={4} placeholder={intl.formatMessage({ id: 'INPUT_MESSAGE' }) } showCount maxLength={2000} />
            </Form.Item>
            <Form.Item>
                <div className="send-message-button">
                    <Button block type="primary" htmlType="submit" loading={loading} size={'large'}>
                        <FormattedMessage id="SEND_MESSAGE" />
                    </Button>
                </div>
            </Form.Item>
        </Form>
    )
}

export default ContactUsForm;