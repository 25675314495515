import { useQuery } from "@apollo/client";
import { Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Row, Select } from "antd";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { client } from "../../apollo-clients";
import { useLayout } from "../../hooks/layout/LayoutContext";
import { TenureDropdownHelper } from "../../shared/components/tenure-dropdown/TenureDropdown";
import { formatNumberWithCurrency } from "../../shared/helpers/number.helper";
import PhoneInput from "../../shared/widgets/phone-input/PhoneInput";
import { GET_LOAN, GET_LOAN_CATEGORY } from "./Financing.gql";
import "./Financing.less";

interface Props {
    form: any;
    currentStep: number;
    setCurrentStep: Dispatch<SetStateAction<number>>;
    isBusiness: boolean;
    setMasterFormData: Dispatch<SetStateAction<any>>;
}

const FinancingForm = ({ form, currentStep, setCurrentStep, isBusiness, setMasterFormData }: Props) => {
    const intl = useIntl();
    const [loanTenure, setLoanTenure] = useState<any>();
    const [loanPurposePersonal, setLoanPurposePersonal] = useState<any>([]);
    const [loanPurposeBusiness, setLoanPurposeBusiness] = useState<any>([]);
    const { layoutState } = useLayout();
    const [countryId, setCountryId] = useState<string>('MY');
    const { data: loanCategoryData, error: loanCategoryError } = useQuery(GET_LOAN_CATEGORY, { client, fetchPolicy: 'cache-and-network', errorPolicy: "all" });
    const { data: loanData } = useQuery(GET_LOAN, { variables: { id: (isBusiness ? process.env.REACT_APP_BUSINESS_LOAN : process.env.REACT_APP_PERSONAL_LOAN) }, fetchPolicy: 'cache-and-network' });

    useEffect(() => {
        setLoanPurposePersonal([]);
        setLoanPurposeBusiness([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (loanData) {
            const result = loanData && loanData?.loan ? loanData?.loan : null;
            setLoanTenure(result);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loanData]);

    useEffect(() => {
        if (loanCategoryError) {
            console.log('loanCategoryError', loanCategoryError);
        }
        else if (loanCategoryData) {
            const _loanPurposePersonal: any = [];
            const _loanPurposeBusiness: any = [];
            loanCategoryData.loanCategories.forEach((val: any) => {
                if (val.loanId === process.env.REACT_APP_PERSONAL_LOAN){
                    _loanPurposePersonal.push(<Select.Option key={val?.id} value={val?.id}>{val?.name[layoutState.locale]}</Select.Option>);
                    setLoanPurposePersonal(_loanPurposePersonal);
                }
                else if (val.loanId === process.env.REACT_APP_BUSINESS_LOAN){
                    _loanPurposeBusiness.push(<Select.Option key={val?.id} value={val?.id}>{val?.name[layoutState.locale]}</Select.Option>);
                    setLoanPurposeBusiness(_loanPurposeBusiness);
                }     
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loanCategoryData, loanCategoryError]);

    const onFinish = (fieldsValue: any) => {
        let variables: any = {
            applyLoanInput: {
                fullName: fieldsValue.fullName,
                email: fieldsValue.email,
                phoneNumber: fieldsValue.phoneNumber,
                loanAmount: fieldsValue.loanAmount,
                loanTenure: fieldsValue.loanTenure,
                loanCategoryId: fieldsValue.loanCategoryId,
                businessType: isBusiness ? fieldsValue.businessType : null,
                companyName: isBusiness ? fieldsValue.companyName : null,
                regNo: isBusiness ? fieldsValue.regNo : null,
                regDate: isBusiness ? fieldsValue.regDate : null,
                activity: isBusiness ? fieldsValue.activity : null,
                years: isBusiness ? fieldsValue.years : null,
                address1: isBusiness ? fieldsValue.address1 : null,
                address2: isBusiness ? fieldsValue.address2 : null,
                state: isBusiness ? fieldsValue.state : null,
                postcode: isBusiness ? fieldsValue.postcode : null,
                city: isBusiness ? fieldsValue.city : null,
                capital: isBusiness ? fieldsValue.capital : null,
            }
        };
        setMasterFormData({
            ...variables.applyLoanInput,
            businessType: fieldsValue?.businessType || null,
        });
        localStorage.setItem("loanDataVariables", JSON.stringify({ ...variables.applyLoanInput }));
        const loanApplication = {
            applicationStatus: 'Processing',
            dateTime: new Date(),
            loanType: isBusiness ? 'Business Loan' : 'Personal Loan',
            loanAmount: fieldsValue.loanAmount,
            phoneNumber: fieldsValue.phoneNumber,
            businessType: fieldsValue?.businessType ? fieldsValue?.businessType : null,
        };
        localStorage.setItem("loanApplication", JSON.stringify(loanApplication));
        setCurrentStep(currentStep + 1);

    }

    const selectCountryId = (_countryId: string) => {
        setCountryId(_countryId);
    }

    const showOnlyIsBusiness = () => {
        if (isBusiness) return <Row gutter={[32, 0]}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <Form.Item name="businessType" label={intl.formatMessage({ id: "FORM_LOAN_BUSINESS_TYPE" })}
                    rules={[
                        { required: true, message: intl.formatMessage({ id: 'FORM_LOAN_BUSINESS_TYPE_REQUIRED' }) }]}
                >
                    <Select placeholder={intl.formatMessage({ id: "FORM_LOAN_TENURE_PLACEHOLDER" })}>
                        <Select.Option key={"partnership"} value={"PARTNERSHIP"}><FormattedMessage id="SOLE_PROPRIETOR" /></Select.Option>
                        <Select.Option key={"sdnBhd"} value={"SDNBHD"}><FormattedMessage id="SDN_BHD" /></Select.Option>
                    </Select>
                </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <Form.Item name="companyName" label={intl.formatMessage({ id: "FORM_COMPANY_NAME" })}
                    rules={[
                        { required: true, message: intl.formatMessage({ id: 'FORM_COMPANY_NAME_REQUIRED' }) }]}
                >
                    <Input placeholder={intl.formatMessage({ id: "FORM_COMPANY_NAME_PLACEHOLDER" })} />
                </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <Form.Item name="regNo" label={intl.formatMessage({ id: "FORM_REG_NO" })}
                    rules={[
                        { required: true, message: intl.formatMessage({ id: 'FORM_REG_NO_REQUIRED' }) },
                        {
                            pattern: new RegExp(/^\S+$/),
                            message: intl.formatMessage({ id: 'FORM_REG_NO_INVALID' })
                        }
                    ]}
                >
                    <Input placeholder={intl.formatMessage({ id: "FORM_REG_NO_PLACEHOLDER" })} />
                </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <Form.Item name="regDate" label={intl.formatMessage({ id: "FORM_REG_DATE" })}
                    rules={[
                        { required: true, message: intl.formatMessage({ id: 'FORM_REG_DATE_REQUIRED' }) }]}
                >
                    <DatePicker placeholder={intl.formatMessage({ id: "FORM_REG_DATE_PLACEHOLDER" })} />
                </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <Form.Item name="activity" label={intl.formatMessage({ id: "FORM_COMPANY_ACTIVITY" })}
                >
                    <Input placeholder={intl.formatMessage({ id: "FORM_COMPANY_ACTIVITY_PLACEHOLDER" })} />
                </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <Form.Item name="years" label={intl.formatMessage({ id: "FORM_YEAR_ESTABLISHED" })}
                    rules={[
                        { required: true, message: intl.formatMessage({ id: 'FORM_YEAR_ESTABLISHED_REQUIRED' }) }]}
                >
                    <InputNumber placeholder={intl.formatMessage({ id: "FORM_YEAR_ESTABLISHED_PLACEHOLDER" })} />
                </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <Form.Item name="address1" label={intl.formatMessage({ id: "FORM_ADDRESS1" })}
                    rules={[
                        { required: true, message: intl.formatMessage({ id: 'FORM_ADDRESS1_REQUIRED' }) }]}
                >
                    <Input placeholder={intl.formatMessage({ id: "FORM_ADDRESS1_PLACEHOLDER" })} />
                </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <Form.Item name="address2" label={intl.formatMessage({ id: "FORM_ADDRESS2" })}
                    rules={[
                        { required: true, message: intl.formatMessage({ id: 'FORM_ADDRESS2_REQUIRED' }) }]}
                >
                    <Input placeholder={intl.formatMessage({ id: "FORM_ADDRESS2_PLACEHOLDER" })} />
                </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <Form.Item name="state" label={intl.formatMessage({ id: "FORM_STATE" })}
                    rules={[
                        { required: true, message: intl.formatMessage({ id: 'FORM_STATE_REQUIRED' }) }]}
                >
                    <Input placeholder={intl.formatMessage({ id: "FORM_STATE_PLACEHOLDER" })} />
                </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <Form.Item name="postcode" label={intl.formatMessage({ id: "FORM_POSTCODE" })}
                    rules={[
                        { required: true, message: intl.formatMessage({ id: 'FORM_POSTCODE_REQUIRED' }) }]}
                >
                    <Input placeholder={intl.formatMessage({ id: "FORM_POSTCODE_PLACEHOLDER" })} />
                </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <Form.Item name="city" label={intl.formatMessage({ id: "FORM_CITY" })}
                    rules={[
                        { required: true, message: intl.formatMessage({ id: 'FORM_CITY_REQUIRED' }) }]}
                >
                    <Input placeholder={intl.formatMessage({ id: "FORM_CITY_PLACEHOLDER" })} />
                </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <Form.Item name="capital" label={intl.formatMessage({ id: "FORM_CAPITAL" })}
                    rules={[
                        { required: true, message: intl.formatMessage({ id: 'FORM_CAPITAL_REQUIRED' }) }]}
                >
                    <InputNumber placeholder={intl.formatMessage({ id: "FORM_CAPITAL_PLACEHOLDER" })} />
                </Form.Item>
            </Col>
        </Row>;
        else return null;
    }

    return (
        <div className="financing-form-container">
            <Form
                form={form}
                layout={"vertical"}
                onFinish={onFinish}
            >
                {showOnlyIsBusiness()}

                <Row gutter={[32, 0]}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <Form.Item name="fullName" label={intl.formatMessage({ id: isBusiness ? 'FORM_CONTACT_PERSON' : "FORM_FULL_NAME" })}
                            rules={[
                                { required: true, message: intl.formatMessage({ id: isBusiness ? 'FORM_CONTACT_PERSON_REQUIRED' : 'FORM_FULL_NAME_REQUIRED' }) }]}
                        >
                            <Input placeholder={intl.formatMessage({ id: isBusiness ? 'FORM_CONTACT_PERSON_PLACEHOLDER' : "FORM_FULL_NAME_PLACEHOLDER" })} />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <Form.Item name="email" label={intl.formatMessage({ id: "FORM_EMAIL" })}
                            rules={[
                                { required: true, message: intl.formatMessage({ id: 'FORM_EMAIL_REQUIRE' }) },
                                { type: "email", message: intl.formatMessage({ id: 'FORM_EMAIL_TYPE_INVALID' }) },
                            ]}>
                            <Input placeholder={intl.formatMessage({ id: 'FORM_EMAIL' })} />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <Form.Item
                            name="phoneNumber"
                            label={intl.formatMessage({ id: "FORM_PHONE_NUMBER" })}
                            rules={[
                                { required: true, message: intl.formatMessage({ id: 'LOGIN_USERNAME_REQUIRED' }) },
                                { min: 8, message: intl.formatMessage({ id: 'LOGIN_USERNAME_VALIDATE' }) }]}>
                            <PhoneInput selectedCountryId={countryId} selectCountryId={selectCountryId} />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <Form.Item name="loanAmount" label={intl.formatMessage({ id: "FORM_LOAN_AMOUNT" })}
                            rules={[
                                { required: true, message: intl.formatMessage({ id: 'FORM_LOAN_AMOUNT_REQUIRED' }) },
                                { type: 'number', min: loanTenure?.minAmount, message: intl.formatMessage({ id: 'MIN_AMOUNT_LOAN' }).replace('{0}', formatNumberWithCurrency(intl, loanTenure?.minAmount)) },
                                { type: 'number', max: loanTenure?.maxAmount, message: intl.formatMessage({ id: 'MAX_AMOUNT_LOAN' }).replace('{0}', formatNumberWithCurrency(intl, loanTenure?.maxAmount)) }
                            ]}
                        >
                            <InputNumber placeholder={intl.formatMessage({ id: "FORM_LOAN_AMOUNT_PLACEHOLDER" })} />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <Form.Item name="loanTenure" label={intl.formatMessage({ id: "FORM_LOAN_TENURE" })}
                            rules={[
                                { required: true, message: intl.formatMessage({ id: 'FORM_LOAN_TENURE_REQUIRED' }) },
                            ]}
                        >
                            <Select placeholder={intl.formatMessage({ id: "FORM_LOAN_TENURE_PLACEHOLDER" })}>
                                {[...TenureDropdownHelper(loanTenure?.minTenure, loanTenure?.maxTenure)]}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <Form.Item name="loanCategoryId" label={intl.formatMessage({ id: "FORM_LOAN_CATEGORY" })}
                            rules={[
                                { required: true, message: intl.formatMessage({ id: 'FORM_LOAN_CATEGORY_REQUIRED' }) }]}
                        >
                            <Select placeholder={intl.formatMessage({ id: "FORM_LOAN_CATEGORY_PLACEHOLDER" })} >
                                {isBusiness ? [...loanPurposeBusiness] : [...loanPurposePersonal]}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                        <Form.Item name="declareInfo" valuePropName="checked" rules={[
                            {
                                required: true, transform: value => (value || undefined),
                                type: 'boolean', message: intl.formatMessage({ id: 'APPLICATION_DECLARE_REQUIRED' })
                            }
                        ]}>
                            <Checkbox><FormattedMessage id="APPLICATION_DECLARE" /></Checkbox>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                        <Form.Item name="declareTNC" valuePropName="checked" rules={[
                            {
                                required: true, transform: value => (value || undefined),
                                type: 'boolean', message: intl.formatMessage({ id: 'APPLICATION_TNC_REQUIRED' })
                            }
                        ]}>
                            <Checkbox><FormattedMessage id="APPLICATION_TNC" values={{
                                0: <Link to="/termsAndConditions"
                                    target="_blank" rel="noopener noreferrer">
                                    <FormattedMessage id="ONLINE_TERMS_CONDITIONS" />
                                </Link>
                            }} /></Checkbox>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                        <Form.Item name="declareConsent" valuePropName="checked" rules={[
                            {
                                required: true, transform: value => (value || undefined),
                                type: 'boolean', message: intl.formatMessage({ id: 'APPLICATION_CONSENT_REQUIRED' })
                            }
                        ]}>

                            <Checkbox><FormattedMessage id="APPLICATION_CONSENT" values={{
                                0: <a href="https://sigmacodersdev.blob.core.windows.net/iffinance-documents/IF-Consent-Form.pdf"
                                    target="_blank" rel="noopener noreferrer">
                                    <FormattedMessage id="IF_CONSENT_FORM" />
                                </a>
                            }} /></Checkbox>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                        <Form.Item className="form-button">
                            <Button type="primary" htmlType="submit">
                                {intl.formatMessage({ id: 'CONTINUE' })}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form >
        </div >
    )
}

export default FinancingForm;