import { Button, Divider, Layout } from 'antd';
import { FormattedMessage } from 'react-intl';
import './GeneralFooter.less';
const { Footer } = Layout;

const GeneralFooter = () => {
    const handleClick = (url: string) => {
        window.open(url, '_self');
    }


    return (
        <Footer className="site-layout-footer">
            <div className="footer-container">
                <div className="footer-buttons" >
                    <Button type="text" className="button-text" onClick={() => handleClick('https://sigmacodersdev.blob.core.windows.net/iffinance-documents/Disclaimer.pdf')} ><FormattedMessage id="DISCLAIMER" /></Button>
                    <Divider type="vertical" className="divider-color" />
                    <Button type="text" className="button-text" onClick={() => handleClick('https://sigmacodersdev.blob.core.windows.net/iffinance-documents/Online-Terms&Conditions.pdf')} ><FormattedMessage id="TNC" /></Button>
                    <Divider type="vertical" className="divider-color" />
                    <Button type="text" className="button-text" onClick={() => handleClick('https://sigmacodersdev.blob.core.windows.net/iffinance-documents/Privacy-Policy.pdf')} ><FormattedMessage id="PRIVACY_POLICY" /></Button>
                </div>
                <div className="main">
                    {window.innerWidth < 576 ?
                        <div>
                            <div>©2021 Infinitec Finsource SDN. BHD. (1395512-U).</div>
                            <div>All rights reserved. </div>
                        </div>
                        : <div>©2021 Infinitec Finsource SDN. BHD. (1395512-U). All rights reserved.</div>}
                </div>
            </div>
        </Footer>
    )
}

export default GeneralFooter;