import { FormattedMessage } from "react-intl";
import "./TermsAndConditions.less"

const TermsAndConditions = () => {
    return (
        <div className="content-container bg-white-container tnc-container">
            <div className="tnc-title">
                <span className="header-1"><FormattedMessage id="ONLINE_TERMS_CONDITIONS" /></span>
            </div>
            <div className="tnc-table">
                <div className="tnc-table-row" >
                    <div>
                        1)
                    </div>
                    <div className="tnc-table-column">
                        <span>
                            I/We confirm that the information given is true and correct and authorize you or your representative/agent to obtain information from any source(s) regarding this application. Should there be any material changes in my/our financial standing and/or all my/our personal data which diminishes my/our ability to meet all demands and claims against me/us, I/we hereby agree to immediately notify you in writing of such changes, failing which, you may assume that my/our financial standing has not suffered detriment.
                        </span>
                        <span className="tnc-italic">
                            Saya/Kami mengesahkan bahawa maklumat yang diberi adalah tepat dan benar dan membenarkan anda atau wakil/ejen anda untuk mendapatkan maklumat dari sebarang sumber-sumber berkenaan dengan permohonan ini. Sekiranya terdapat perubahan penting terhadap kedudukan kewangan saya/kami dan/atau data peribadi saya/kami yang menjejaskan keupayaan saya/kami untuk memenuhi semua permintaan dan tuntutan terhadap saya/kami, dengan ini saya/kami bersetuju untuk memaklumkan kepada anda dengan segera secara bertulis tentang perubahan tersebut, jika tidak, anda boleh mengandaikan bahawa kedudukan kewangan saya/kami adalah tidak terjejas
                        </span>
                    </div>
                </div>
                <div className="tnc-table-row" >
                    <div>
                        2)
                    </div>
                    <div className="tnc-table-column">
                        <span>
                            I/We understand that in order for you to consider the request herein for credit or loan/financing facilities and to grant or continue to make available the facilities or for the purpose of monitoring and/or recovery of any outstanding sums due and owing by me/us under the facility(ies), my/our or my/our guarantor(s) and security party(ies) personal, credit, collateral information or other collateral details, whether relating directly or indirectly to me/us, I/we hereby irrevocably consent and authorize your company to forward, made known, disclose, obtain for verification and retain all information or documents (by way of hard copy or electronically stored) including but not limited to my/our income statement, employment details and bank statements furnished by me/us with the issuer of the information or documents, including but not limited to my/our employer, business partners, suppliers, clients, corporate bodies, banker, credit rating agencies, relevant authorities and next of kin (including but not limited for any other status checks purposes from time to time (present and future)).
                        </span>
                        <span className="tnc-italic">
                            Saya/Kami sedia maklum bahawa bagi membolehkan anda untuk mempertimbangkan permohonan bagi kemudahan kredit atau pinjaman/pembiayaan dan untuk memberi atau terus menyediakan kemudahan tersebut bagi tujuan pemantauan dan/atau mendapatkan semula jumlah yang perlu dibayar dan yang masih terhutang oleh saya/kami di bawah kemudahan ini, butir-butir peribadi, kredit, maklumat cagaran atau cagaran lain saya/kami, penjamin dan pihak sekuriti saya/kami, sama ada yang berkaitan secara langsung atau tidak langsung dengan saya/kami, saya/kami dengan muktamad memberi kebenaran dan kuasa kepada anda untuk mengemukakan, memaklumkan, mendedahkan, mendapatkan bagi tujuan pengesahan maklumat atau dokumen (dalam bentuk salinan keras atau simpanan elektronik) dan menyimpan kesemuanya termasuk tetapi tidak terhad kepada penyata pendapatan saya/kami, butir-butir pekerjaan dan penyata bank yang diberikan oleh saya/kami dengan penerbit maklumat atau dokumen, termasuk tetapi tidak terhad kepada majikan saya/kami, rakan perniagaan, pembekal, pelanggan, badan-badan korporat, pihak bank, agensi penarafan kredit, pihak berkuasa berkenaan dan para waris saya/kami (termasuk tetapi tidak terhad bagi tujuan semakan status lain dari semasa ke semasa (pada masa ini dan pada masa hadapan).
                        </span>
                    </div>
                </div>
                <div className="tnc-table-row" >
                    <div>
                        3)
                    </div>
                    <div className="tnc-table-column">
                        <span>
                            I/We understand that you will not be able to consider/process my/our application herein for the facilities or continue to provide the facilities unless you can disclose to, share with or receive all my/our personal data from the parties/relevant parties in compliance with Personal Data Protection Act 2010 and Credit Reporting Agencies Act 2010. The information I/we provided you, as contained in the application form will be maintained in your/parties'/ relevant parties' records for the purposes for which it was given. Without the information you will not be able to provide me/us with the loan/financing facility that I/we have applied for.
                        </span>
                        <span className="tnc-italic">
                            Saya/Kami sedia maklum bahawa anda tidak dapat mempertimbangkan/memproses permohonan saya/kami ini untuk kemudahan tersebut atau terus menyediakan kemudahan tersebut melainkan anda boleh mendedahkan, berkongsi atau menerima data peribadi saya/kami kepada/dengan/daripada pihak-pihak/pihak-pihak berkaitan bagi mematuhi Akta Perlindungan Data Peribadi 2010 dan Akta Agensi Pelaporan Kredit 2010. Maklumat yang diberikan oleh saya/kami, seperti yang terkandung dalam borang permohonan akan dikekalkan dalam rekod anda/pihak-pihak/pihak-pihak berkaitan bagi maksud yang diniatkan. Tanpa maklumat tersebut, anda tidak dapat memberikan saya/kami kemudahan pinjaman/pembiayaan yang dipohon oleh saya/kami itu,
                        </span>
                    </div>
                </div>
                <div className="tnc-table-row" >
                    <div>
                        4)
                    </div>
                    <div className="tnc-table-column">
                        <span>
                            I/We understand that the acceptance of this application by you does not constitute your automatic agreement to grant me/us the facilities and you reserve the right to reject the application at your sole discretion without stating any reason. This application and supporting documents will remain the property of your company regardless of whether the application is approved. The facilities, if approved by you shall be subject to the terms and conditions more specifically set out in the Letter(s) of Offer(s), Facilities Agreement/Loan Agreement/Financing Documents, the Charge or Deed of Assignment or such other security document, you deem necessary and shall further be subjected to reduction or cancellation prior to the conclusion of the facilities at your discretion.
                        </span>
                        <span className="tnc-italic">
                            Saya/Kami memahami bahawa penerimaan permohonan ini oleh anda tidak bererti anda bersetuju secara automatik untuk memberikan saya/kami kemudahan tersebut dan anda berhak menolak permohonan ini mengikut budi bicara anda tanpa memberikan sebarang alasan. Permohonan ini dan segala dokumen yang dilampirkan akan menjadi hak milik anda tidak kira sama ada permohonan diluluskan atau tidak. Kemudahan tersebut, jika diluluskan oleh anda akan tertakluk pada terma dan syarat yang dinyatakan secara khusus dalam Surat Tawaran, Perjanjian Kemudahan/Perjanjian Pinjaman/Dokumen Pembiayaan, Gadaian atau Surat Ikatan Penyerahhakan atau dokumen sekuriti seumpamanya yang lain yang dianggap perlu oleh anda dan seterusnya akan tertakluk pada pengurangan atau pembatalan sebelum kemudahan ini dimuktamadkan mengikut budi bicara anda.
                        </span>
                    </div>
                </div>
                <div className="tnc-table-row" >
                    <div>
                        5)
                    </div>
                    <div className="tnc-table-column">
                        <span>
                            I/We authorize your company to contact the next of kin/agent in the event of default/late payment.
                        </span>
                        <span className="tnc-italic">
                            Saya/Kami membenarkan anda untuk menghubungi waris/saudara terdekat/ejen jika berlaku kegagalan/kelewatan pembayaran.
                        </span>
                    </div>
                </div>
                <div className="tnc-table-row" >
                    <div>
                        6)
                    </div>
                    <div className="tnc-table-column">
                        <span>
                            I/We am/are aware that it is advisable to protect the total loan/financing amount with a Life Policy/GMRTA or any other suitable form of insurance including the houseowner/commercial fire insurance.
                        </span>
                        <span className="tnc-italic">
                            Saya/Kami menyedari kepentingan melindungi jumlah pinjaman/pembiayaan dengan polisi nyawa/GMRTA atau polisi insurans lain seumpamanya yang bersesuaian termasuk polisi insurans pemilik rumah/kebakaran komersial.
                        </span>
                    </div>
                </div>
                <div className="tnc-table-row" >
                    <div>
                        7)
                    </div>
                    <div className="tnc-table-column">
                        <span>
                            I/We undertake to submit all documentation as required by your company failing which the loan/financing will be repriced at the prescribed rate as applicable at the time of receipt of full documentation by your company.
                        </span>
                        <span className="tnc-italic">
                            Saya/Kami akan menyerahkan semua dokumen yang diperlukan oleh anda, kegagalan berbuat demikian akan menyebabkan pinjaman/pembiayaan akan diberikan pada kadar yang ditetapkan dan terpakai pada waktu penerimaan dokumen lengkap oleh anda.
                        </span>
                    </div>
                </div>
                <div className="tnc-table-row" >
                    <div>
                        8)
                    </div>
                    <div className="tnc-table-column">
                        <span>
                            I/We understand and agree that any personal information collected or held by the your company (whether contained in this application or otherwise obtained) may be held, used, and disclosed by your company to individuals/organization related to and associated with your company or any selected third party (within or outside of Malaysia) including relevant authorities for the purpose of processing this application and providing subsequent service for this and other financial products and service and to communicate with me/us for such purposes. I/We understand that I/we have a right to obtain access to and to request correction of any personal information held by your company concerning me/us. Such request can be made to any of your company’s Customer Service Centre.
                        </span>
                        <span className="tnc-italic">
                            Saya/Kami faham dan bersetuju bahawa sebarang maklumat peribadi yang dikumpulkan atau dipegang oleh anda (sama ada terkandung dalam permohonan ini atau diperolehi dengan cara lain) boleh dipegang, digunakan dan diberikan oleh anda kepada individu/organisasi yang berhubung dan berkaitan dengan anda atau mana-mana pihak ketiga yang dipilih (di dalam atau di luar Malaysia) termasuk pihak berkuasa berkenaan bagi tujuan memproses permohonan ini dan memberikan khidmat seterusnya untuk produk dan khidmat kewangan yang lain dan untuk berkomunikasi dengan saya/kami untuk tujuan tersebut. Saya/Kami faham bahawa saya/kami berhak memperoleh akses kepada dan memohon pembetulan sebarang maklumat peribadi yang dipegang oleh anda berkaitan dengan saya/kami. Permohonan seperti itu boleh dibuat di mana-mana Pusat Khidmat Pelanggan anda.
                        </span>
                    </div>
                </div>
                <div className="tnc-table-row" >
                    <div>
                        9)
                    </div>
                    <div className="tnc-table-column">
                        <span>
                            To receive information regarding exclusive promotions and offers from your company, please tick below
                        </span>
                        <span className="tnc-italic">
                            Untuk menerima maklumat tentang promosi dan tawaran eksklusif daripada anda, sila tandakan di bawah:
                        </span>
                        <span>
                            I/We agree that any personal information collected or held by your company (whether contained in this application or otherwise obtained) may be disclosed by your company to any selected third party for the purposes of cross marketing, direct marketing, and data matching, and to communicate with me/us for such purposes. I/We understand that I/we have a right to obtain access to and to request correction of any personal information held by your company concerning me/us. Such request can be made to any of your company’s Customer Service Centre.
                        </span>
                        <span className="tnc-italic">
                            Saya/Kami bersetuju bahawa sebarang maklumat peribadi yang dikumpulkan atau dipegang oleh anda (sama ada terkandung dalam permohonan ini atau diperolehi dengan cara lain) boleh diberikan oleh anda kepada mana-mana pihak ketiga yang dipilih bagi tujuan pemasaran silang, pemasaran langsung, dan pemadanan data dan untuk berkomunikasi dengan saya/kami untuk tujuan tersebut. Saya/Kami faham bahawa saya/kami berhak memperoleh akses kepada dan memohon pembetulan sebarang maklumat peribadi yang dipegang oleh anda berkaitan dengan saya/kami. Permohonan seperti itu boleh dibuat di mana-mana Pusat Khidmat Pelanggan anda.
                        </span>
                    </div>
                </div>
                <div className="tnc-table-row" >
                    <div>
                        10)
                    </div>
                    <div className="tnc-table-column">
                        <span>
                            I/We confirm that I am/we are not a Director of your company, a member of the management team of your company having authority and responsibility in the planning, directing, and/or controlling of activities and/or employee of your company involved in the appraising, approving or reviewing of financing facilities or controlling of shareholders (“Specified Person”). I/We also confirm that I am/we are not related to a Specified Person and no Specified Person is acting as my/our guarantor for this loan/financing. If I/we become a Specified Person, become related to a Specified Person or if a Specified Person becomes my/our guarantor for this loan/financing, I/we agree to notify your company of the change in my status, and where applicable, the name and my/our relationship with the Specified Person, when I/we become aware of such relationship.
                        </span>
                        <span className="tnc-italic">
                            Saya/Kami mengesahkan bahawa saya/kami bukan Pengarah syarikat anda, ahli pengurusan syarikat anda yang mempunyai kuasa dan tanggungjawab dalam perancangan, mengarahkan, dan/atau mengawal aktiviti dan/atau pekerja syarikat anda yang terlibat dalam menilai, meluluskan atau mengkaji semula kemudahan pembiayaan atau pengawalan pemegang saham ("Orang Yang Ditentukan"). Saya/Kami juga mengesahkan bahawa saya/kami tidak ada kaitan dengan Orang Yang Tertentu dan tiada Orang Khusus yang bertindak sebagai penjamin saya/kami untuk pinjaman/pembiayaan ini. Sekiranya saya/kami menjadi Orang Yang Ditentukan, menjadi berkaitan dengan Orang Yang Ditentukan atau jika Orang Yang Ditentukan menjadi penjamin saya/kami untuk pinjaman/pembiayaan ini, saya/kami bersetuju untuk memberitahu anda tentang perubahan dalam status saya, dan jika berkenaan, nama dan hubungan saya/kami dengan Orang yang Ditentukan, apabila saya/kami menyedari hubungan sedemikian.
                        </span>
                    </div>
                </div>
                <div className="tnc-table-row" >
                    <div>
                        11)
                    </div>
                    <div className="tnc-table-column">
                        <span>
                            I/We hereby confirm that I/we have been given a copy of the Product Disclosure Sheet by my/our agent and I/we have fully understood the contents of the Product Disclosure Sheet as explained to me/us by my/our agent.
                        </span>
                        <span className="tnc-italic">
                            Saya/Kami dengan ini mengesahkan bahawa saya/kami telah diberikan Salinan Pendedahan Produk oleh ejen saya/kami dan saya/kami telah memahami sepenuhnya kandungan-kandungan Salinan Pendedahan Produk seperti yang telah diterangkan kepada saya/kami oleh ejen saya/kami.
                        </span>
                    </div>
                </div>
                <div className="tnc-table-row" >
                    <div>
                        12)
                    </div>
                    <div className="tnc-table-column">
                        <span>
                            I/We hereby confirm that I/we have been given a copy of the Product Disclosure Sheet by my/our agent and I/we have fully understood the contents of the Product Disclosure Sheet as explained to me/us by my/our agent.
                        </span>
                        <span className="tnc-italic">
                            Saya/Kami bersetuju, membenarkan dan memberi kuasa kepada mana-mana agensi pelaporan kredit yang didaftarkan di bawah Akta Agensi Pelaporan Kredit 2010 ("CRA") untuk memproses dan mendedahkan mana-mana maklumat kredit saya/kami (sebagaimana ditakrifkan dalam CRA) dalam rekod pelaporan kredit agensi-agensi dan untuk mengakses, memproses dan mendedahkan mana-mana maklumat kredit saya/kami dalam rekod Biro Kredit, Bank Negara Malaysia yang merangkumi Sistem Maklumat Rujukan Kredit Pusat (CCRIS) kepada anda untuk tujuan pemprosesan atau pertimbangan permohonan/permohonan tambahan saya/kami untuk produk atau perkhidmatan anda termasuk tetapi tidak terhad kepada penilaian kredit, ulasan kredit, pemantauan kredit dan proses pemulihan hutang. Sekiranya anda menghendaki apa-apa pemprosesan sedemikian diproses selanjutnya oleh mana-mana pusat pemprosesannya yang terletak di luar Malaysia, saya/kami dengan ini memberikan persetujuan saya/kami kepada anda untuk mendedahkan maklumat kredit saya/kami yang diperoleh daripada agensi pelaporan kredit ke lokasi-lokasi di luar Malaysia.
                        </span>
                    </div>
                </div>
                <div className="tnc-table-row" >
                    <div className="tnc-table-column">
                        <span>
                            I/We have read and understood your above terms and conditions for applications for facilities/financing. I/We hereby undertake and agree to be bound by your terms and conditions as mentioned therein.
                        </span>
                        <span className="tnc-italic">
                            Saya/kami telah membaca dan memahami terma dan syarat di atas bagi permohonan pinjaman/pembiayaan ini. Dengan ini, saya/kami mengaku janji dan bersetuju untuk mematuhi terma dan syarat tuan seperti yang disebut di dalam ini.
                        </span>
                        <span>
                            Signed by the following persons who have been duly authorised by the firm’s partner(s)/company director(s) and/or shareholder(s): /
                        </span>
                        <span className="tnc-italic">
                            Ditandatangani oleh orang berikut yang telah diberi kuasa dengan sewajarnya oleh rakan kongsi/pengarah syarikat/pinjaman dan/atau pemegang saham:
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TermsAndConditions;