import './PhoneInput.less';
import { useQuery } from "@apollo/client";
import { Input, Select } from "antd";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { GET_SIGNUP_COUNTRIES } from "../../gql/countries.gql";
import { MobileOutlined } from '@ant-design/icons';
const { Option } = Select;

interface Props {
    value?: string;
    onChange?: (value: string) => void;
    selectedCountryId?: string;
    selectedDialCode?: string;
    selectCountryId?: (countryCode: string) => void;
}

const PhoneInput = ({ value, onChange, selectCountryId, selectedCountryId = 'MY', selectedDialCode = '+60' }: Props) => {
    const { data } = useQuery(GET_SIGNUP_COUNTRIES);
    const { formatMessage } = useIntl();
    const [countries, setCountries] = useState([]);
    const [countryCode, setCountryCode] = useState(selectedDialCode);
    const intl = useIntl();

    useEffect(() => {
        if (data) { setCountries(data.signUpCountries); }
    }, [data]);

    const onPhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            const { value } = e.target;
            const reg = /^\d*(\d*)?$/;
            if ((!isNaN(+value) && reg.test(value)) || value === '') {
                onChange(countryCode + value.replace(/0*(\d+)/, '$1'));
            } else {
                onChange(countryCode)
            }
        }
    };

    const onCountryCodeChange = (selectedValue: string, option: any) => {
        if (onChange) {
            onChange(option.title + (value?.replace(countryCode, '') || ''));
            setCountryCode(option.title);
        }
        if (selectCountryId) {
            selectCountryId(option.value);
        }
    }

    const selectCountries = (
        <Select showSearch className="country-selector" dropdownClassName="country-dropdown"
            defaultValue={selectedCountryId} optionFilterProp="children" onChange={onCountryCodeChange}
            filterOption={(input, option) =>
                option?.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
                optionA.children[1].toLowerCase().localeCompare(optionB.children[1].toLowerCase())
            }
            optionLabelProp="displaytitle">
            {
                countries.map((d: any) => {
                    return (<Option key={d.id} value={d.id} title={`+${d.dialCode}`} displaytitle={
                        <div className="country-selected-title">
                            <img src={`https://sigmacodersdev.blob.core.windows.net/flags/${d.id}.svg`} alt={d.id} />
                            {`+${d.dialCode}`}
                        </div>}
                        className="country-dropdown-item">
                        <img src={`https://sigmacodersdev.blob.core.windows.net/flags/${d.id}.svg`} alt={d.id} />
                        {`${formatMessage({ id: 'COUNTRIES_' + d.id })} (+${d.dialCode})`}
                    </Option>)
                })
            }
        </Select>
    );

    return (
        <>
            <div className="phone-container">
                {selectCountries}
                <Input placeholder={intl.formatMessage({ id: 'PHONE_INPUT_PLACEHOLDER' })} maxLength={20}
                    onChange={onPhoneNumberChange} value={value?.replace(countryCode, '')}  style={{marginLeft:"12px"}}
                    prefix={<MobileOutlined />} />
            </div>
        </>
    );
}

export default PhoneInput;