import "./ContactUs.less";
import { FormattedMessage } from "react-intl";
import ContactUsContainer from "../../shared/components/contact-us-container/ContactUsContainer";
import { useEffect } from "react";

const ContactUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="contact-us-banner">
                <span className="header-1 font-align-center"><FormattedMessage id="CONTACT_US" /></span>
                <span className="font-body-1 font-align-center"><FormattedMessage id="SEND_US_ENQUIRIES" /></span>
            </div>
            <div className="bg-white-container">
                <ContactUsContainer />
            </div>

        </>
    );
};

export default ContactUs;
