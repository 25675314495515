import { FormattedMessage } from "react-intl";
import "./FinancingContainer.less";

export const PersonalLoanCardComponent = () => {
    return (
        <div className="financing-content">
            <div className="financing-content-title theme-text-color header-2">
                <FormattedMessage id="PERSONAL_LOAN" />
            </div>
            <div className="financing-content-subtitle">
                <FormattedMessage id="PERSONAL_LOAN_INFO" />
            </div>
            <ul>
                <li><FormattedMessage id="PERSONAL_LOAN_CARD_1" /></li>
                <li><FormattedMessage id="PERSONAL_LOAN_CARD_2" /></li>
                <li><FormattedMessage id="PERSONAL_LOAN_CARD_3" /></li>
                <li><FormattedMessage id="PERSONAL_LOAN_CARD_4" /></li>
                <li><FormattedMessage id="PERSONAL_LOAN_CARD_5" /></li>
                <li><FormattedMessage id="PERSONAL_LOAN_CARD_6" /></li>
                <li><FormattedMessage id="PERSONAL_LOAN_CARD_7" /></li>
                <li><FormattedMessage id="PERSONAL_LOAN_CARD_8" /></li>
            </ul>
        </div>
    )
}

export const BusinessLoanCardComponent = () => {
    return (
        <div className="financing-content">
            <div className="financing-content-title theme-text-color header-2">
                <FormattedMessage id="BUSINESS_LOAN" />
            </div>
            <div className="financing-content-subtitle">
                <FormattedMessage id="BUSINESS_LOAN_INFO" />
            </div>
            <ul>
                <li><FormattedMessage id="BUSINESS_LOAN_CARD_1" /></li>
                <li><FormattedMessage id="BUSINESS_LOAN_CARD_2" /></li>
                <li><FormattedMessage id="BUSINESS_LOAN_CARD_3" /></li>
                <li><FormattedMessage id="BUSINESS_LOAN_CARD_4" /></li>
                <li><FormattedMessage id="BUSINESS_LOAN_CARD_5" /></li>
                <li><FormattedMessage id="BUSINESS_LOAN_CARD_6" /></li>
            </ul>
        </div>
    )
}