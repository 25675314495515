import { gql } from "@apollo/client";

export const APPLY_LOAN = gql`
mutation ApplyLoan($applyLoanInput:ApplyLoanInput!){
    applyLoan(applyLoanInput:$applyLoanInput){
        status,
        id,
        a,
    }
}
`;

export const GET_LOANS = gql`
query Loans{
    loans{
        id,
        name,
        route,
        interest,
        legalFee,
        stampDuty,
        minAmount,
        maxAmount,
        minTenure,
        maxTenure,
        lockInPeriod,
    }
}
`;

export const GET_LOAN = gql`
query Loan($id: String!){
    loan(id:$id){
        id,
        name,
        route,
        interest,
        legalFee,
        stampDuty,
        minAmount,
        maxAmount,
        minTenure,
        maxTenure,
        lockInPeriod,
    }
}
`;

export const GET_LOAN_CATEGORY = gql`
query LoanCategories{
    loanCategories{
        id,               
        name,           
        loanId,   
    }
}
`;

export const GET_CODE = gql`
    mutation UserVerification($verificationType:String!, $phone: String){ 
        userVerification(verificationType:$verificationType, phone:$phone){ 
            status,
            a,
        } 
    }
`;

export const UPLOAD_LOAN_DOCUMENTS = gql`
 mutation uploadLoanDocuments($uploadLoanInput:UploadLoanInput!){
     uploadLoanDocuments(uploadLoanInput:$uploadLoanInput){
          status, documentPath

        }
    }
`;

export const DELETE_UPLOADED_LOAN_DOCUMENTS = gql`
    mutation DeleteUploadedLoanDocuments($loanId:String!, $deleteCategory: String, $deleteUrl: String){ 
        deleteUploadedLoanDocuments(loanId:$loanId, deleteCategory:$deleteCategory, deleteUrl:$deleteUrl){ 
            status
        } 
    }
`;

export const GET_LOAN_APPLICATION_BY_ID = gql`
query loanApplicationById($id: String!){
    loanApplicationById(id: $id){
        id,                  
        documents,          
    }
}
`;

export const APPLY_LOAN_COMPLETE = gql`
mutation ApplyLoanComplete($id: String!){
    applyLoanComplete(id:$id){
        status,
    }
}
`;

export const LOAN_CANCEL = gql`
mutation LoanCancellation($id: String!){
    loanCancellation(id:$id){
        status,
    }
}
`;
