import { useMutation } from "@apollo/client";
import { Button, Form, Alert, Input } from "antd";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import PhoneInput from "../../shared/widgets/phone-input/PhoneInput";
import { FORGOT_PASSWORD_PHONE, FORGOT_PASSWORD_REQUEST_CODE } from "./auth.gql";
import CodeRequestButton from "./CodeRequestButton";
import useNotification from "../../hooks/layout/useNotification";
import { ReactComponent as TagLogo } from '../../assets/images/TAC.svg';
import { setTokenCookie } from '../../shared/helpers/set-token-cookies.helper';
import useSpinner from '../../hooks/layout/useSpinner';

interface Props {
    next?: () => void;
}

const ForgotPasswordForm = ({ next }: Props) => {
    const [form] = Form.useForm();
    const [error, setError] = useState<string>();
    const intl = useIntl();
    const navigate = useNavigate();
    const { setLoading } = useSpinner();
    const { setErrorNotification } = useNotification();
    const [forgotPasswordPhone, { data, loading, error: forgotPasswordError }]
        = useMutation(FORGOT_PASSWORD_PHONE, { fetchPolicy: "no-cache", errorPolicy: "all" });
    const [requestCode, { data: requestCodeData, error: requestCodeError }] = useMutation(FORGOT_PASSWORD_REQUEST_CODE, { fetchPolicy: "no-cache", errorPolicy: "all" });
    const [phone, setPhone] = useState<string>();
    const [countryId, setCountryId] = useState<string>('MY');
    const [otpCode, setOtpCode] = useState<string>();

    useEffect(() => {
        if (loading) setLoading(true);
        else setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    useEffect(() => {
        if (data && data.forgotPasswordPhone) {
            const { a, r, sid } = data.forgotPasswordPhone;
            if(a) setTokenCookie('scf-at', a);
            if(sid) setTokenCookie('scf-sid', sid);
            if(r) {
                setTokenCookie('scf-rt', r);
                if (next) next();
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const onFinish = (values: any) => {
        forgotPasswordPhone({
            variables: {
                phoneNumber: phone,
                code: values.code
            }
        });
    };

    useEffect(() => {
        if (requestCodeData && requestCodeData.forgotPasswordRequestCode && requestCodeData.forgotPasswordRequestCode.sid) {
            setOtpCode(requestCodeData.forgotPasswordRequestCode.sid);
        }
    }, [requestCodeData])

    useEffect(() => {
        if (forgotPasswordError || requestCodeError) {
            let errorMessage: string = '';
            if (forgotPasswordError?.message) {
                errorMessage = forgotPasswordError?.message;
            }
            else if (requestCodeError?.message) {
                errorMessage = requestCodeError?.message;
            }

            if (errorMessage.indexOf('INVALID_LOGIN') > -1) {
                setError('AUTH_ERROR_INVALID_LOGIN');
            } else if (errorMessage.indexOf('ACCOUNT_NOT_EXISTED') > -1) {
                setError('AUTH_ERROR_ACCOUNT_NOT_EXISTED');
            } else if (errorMessage.indexOf('INVALID_CODE') > -1) {
                setError('AUTH_ERROR_INVALID_CODE');
            } else if (errorMessage.indexOf('ACCOUNT_EXISTED') > -1) {
                setErrorNotification(intl.formatMessage({ id: 'AUTH_ERROR_ACCOUNT_EXISTED' }));
                navigate("/auth");
            } else if (errorMessage.indexOf('FIRST_LOGIN') > -1) {
                setErrorNotification(intl.formatMessage({ id: 'AUTH_ERROR_FIRST_LOGIN' }));
                navigate("/first-login");
            } else if (errorMessage.indexOf('FREQUENT_ACTION') > -1) {
                setErrorNotification(intl.formatMessage({ id: 'AUTH_REQUEST_FREQUENT' }));
            }

        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forgotPasswordError, requestCodeError])

    const requestStatus = () => {
        setError(undefined);
        requestCode({
            variables: {
                phoneNumber: phone,
            }
        })
    }

    const onValuesChange = ({ phoneNumber }: any) => {
        if (phoneNumber) {
            setPhone(phoneNumber);
        }
    }

    const selectCountryId = (countryId: string) => {
        setCountryId(countryId);
    }

    return (
        <>
            <Form
                name="form"
                form={form}
                onValuesChange={onValuesChange}
                initialValues={{ isMemberId: '0' }}
                onFinish={onFinish}
            >
                {error && <Alert
                    className="auth-error"
                    message={<FormattedMessage id={error} />}
                    type="error"
                    showIcon
                />}
                <Form.Item
                    name="phoneNumber"
                    rules={[
                        { required: true, message: intl.formatMessage({ id: 'LOGIN_USERNAME_REQUIRED' }) },
                        { min: 8, message: intl.formatMessage({ id: 'LOGIN_USERNAME_VALIDATE' }) }]}>
                    <PhoneInput selectedCountryId={countryId} selectCountryId={selectCountryId} />
                </Form.Item>
                {
                    process.env.REACT_APP_SHOW_OTP === '1' && otpCode &&
                    <div>{`OTP: ${otpCode}`}</div>
                }
                <div className="sms-same-row-container">
                    <Form.Item
                        name="code"
                        rules={[
                            { required: true, message: intl.formatMessage({ id: 'LOGIN_CODE_REQUIRED' }) },
                            { min: 6, message: intl.formatMessage({ id: 'AUTH_INVALID_CODE' }) }
                        ]}>
                        <Input maxLength={6} placeholder={intl.formatMessage({ id: 'PHONE_VERIFICATION_INPUT_PLACEHOLDER' })} prefix={<TagLogo />} />
                    </Form.Item>
                    <CodeRequestButton
                        phoneNumber={phone}
                        hasError={requestCodeError ? true : false}
                        requestStatus={requestStatus} setError={setError} />
                </div>
                <Form.Item className="auth-button">
                    <Button block type="primary" htmlType="submit" loading={loading}>
                        <FormattedMessage id="GENERAL_NEXT" />
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

export default ForgotPasswordForm;
