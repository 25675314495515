import { Col, Form, Row, Steps } from "antd";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { BusinessFinancingLoanExpansion, BusinessFinancingLoanNeeds, BusinessFinancingSdnBhd, BusinessFinancingSoleProprietor } from "../FinancingComponents";
import FinancingForm from "../FinancingForm";
import FinancingOtpForm from "../FinancingOtpForm";
import "./FinancingBusiness.less";
import FinancingBusinessUploadSBForm from "./FinancingBusinessUploadSBForm";
import FinancingBusinessUploadSoleForm from "./FinancingBusinessUploadSoleForm";

const FinancingBusiness = () => {
    const [form] = Form.useForm();
    const [masterFormData, setMasterFormData] = useState<any>();
    const [currentStep, setCurrentStep] = useState(0);
    const { Step } = Steps;

    const steps = [
        {
            title: "DETAILS",
            content: <FinancingForm form={form} currentStep={currentStep} setCurrentStep={setCurrentStep} isBusiness={true} setMasterFormData={setMasterFormData} />
        },
        {
            title: "OTP",
            content: <FinancingOtpForm form={form} currentStep={currentStep} setCurrentStep={setCurrentStep} />
        },
        {
            title: "DOCUMENTS",
            content:
                masterFormData && masterFormData?.businessType === "PARTNERSHIP" ?
                    <FinancingBusinessUploadSoleForm form={form} />
                    : <FinancingBusinessUploadSBForm form={form} />
        },
    ];

    return (
        <>
            <div className="business-financing-banner">
                <div className="business-financing-header">
                    <span className="header-1">
                        <FormattedMessage id="BUSINESS_LOAN" />
                    </span>
                    <span className="font-body-2">
                        <FormattedMessage id="BUSINESS_LOAN_DESC" />
                    </span>
                </div>
                <div className="financing-component-container">
                    <Row gutter={[36, 12]}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} className='financing-col'>
                            <BusinessFinancingLoanNeeds />
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} className='financing-col'>
                            <BusinessFinancingLoanExpansion />
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="bg-gray-container">
                <div className="content-container business-financing-container">
                    <div className="business-financing-content  financing-container-content">

                        <Row gutter={[36, 12]}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} className='financing-col'>
                                <BusinessFinancingSoleProprietor />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} className='financing-col'>
                                <BusinessFinancingSdnBhd />
                            </Col>
                        </Row>
                    </div>
                    <div className="bg-gray-container">
                        <div className="business-header-container">
                            <div className="header-2"><FormattedMessage id="APPLY_BUSINESS_LOAN" /></div>
                            <div><FormattedMessage id="BUSINESS_LOAN_SUBTITLE" /></div>
                        </div>
                        <div className="business-steps-container">
                            <Steps direction="horizontal" current={currentStep} size="small">
                                {steps.map(item => (
                                    <Step key={item.title} title={<FormattedMessage id={item.title} />} />
                                ))}
                            </Steps>
                            {steps[currentStep].content}
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default FinancingBusiness;