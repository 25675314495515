import "./AboutUs.less";
import { FormattedMessage } from "react-intl";
import { ReactComponent as FastLogo } from '../../assets/images/fast.svg';
import { ReactComponent as HasselFreeLogo } from '../../assets/images/hassle-free.svg';
import { ReactComponent as ReliableLogo } from '../../assets/images/reliable.svg';
import { useEffect } from "react";

const AboutUs = () => {

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [])

	return (
		<>
			<div className="about-us-banner">
				<span className="header-1 font-align-center"><FormattedMessage id="ABOUT_US" /></span>
				<span className="font-body-1 font-align-center"><FormattedMessage id="ABOUT_US_DESCRIPTION" /></span>
			</div>
			<div className="bg-white-container">
				<div className="content-container about-us-container">
					<div>
						<div className="about-us-info-1">
							<span className="header-1 font-align-center"><FormattedMessage id="ABOUT_US_CONTENT_TITLE" /></span>
							<span className="header-2 font-align-center"><FormattedMessage id="ABOUT_US_CONTENT_SUB_TITLE" /></span>
						</div>
						<div className="about-us-info-2">
							<div>
								<FastLogo />
								<span className="font-body-1"><FormattedMessage id="FAST" /></span>
							</div>
							<div>
								<HasselFreeLogo />
								<span className="font-body-1"><FormattedMessage id="HASSLE_FREE" /></span>
							</div>
							<div>
								<ReliableLogo />
								<span className="font-body-1"><FormattedMessage id="RELIABLE" /></span>
							</div>
						</div>
						<div className="about-us-info-3">
							<div>
								<div className="mission-vision">
									<div className="font-subtitle1"><FormattedMessage id="OUR_VISION" />:</div>
									<div>
										<ol type="1" className="without-number">
											<li className="font-body-2"><FormattedMessage id="OUR_VISION_DESCRIPTION" /></li>
										</ol>
									</div>
								</div>
								<div className="mission-vision">
									<div className="font-subtitle1"><FormattedMessage id="OUR_MISSION" />:&nbsp;</div>
									<div>
										<ol type="1">
											<li className="font-body-2"><FormattedMessage id="OUR_MISSION_DECRIPTION_1" /></li>
											<li className="font-body-2"><FormattedMessage id="OUR_MISSION_DECRIPTION_2" /></li>
											<li className="font-body-2"><FormattedMessage id="OUR_MISSION_DECRIPTION_3" /></li>
										</ol>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='bg-grey'>
				<div className="about-us-our-values">
					<div className="header-1 our-value-title"><FormattedMessage id="OUR_VALUES" /></div>
					<div className="our-values-details">
						<div>
							<div className="header-2"><FormattedMessage id="PRUDANCE" /></div>
							<p className="font-caption"><FormattedMessage id="PRUDANCE_DESCRIPTION" /></p>
						</div>
						<div>
							<div className="header-2"><FormattedMessage id="INTEGRITY" /></div>
							<p className="font-caption"><FormattedMessage id="INTEGRITY_DESCRIPTION_1" /></p>
							<p className="font-caption"><FormattedMessage id="INTEGRITY_DESCRIPTION_2" /></p>
						</div>
						<div>
							<div className="header-2"><FormattedMessage id="RESPECT" /></div>
							<p className="font-caption"><FormattedMessage id="RESPECT_DESCRIPTION" /></p>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default AboutUs;