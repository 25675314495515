import { gql } from "@apollo/client";

export const REGISTER = gql`
query SignUpCountries{
    signUpCountries{
        id,
        dialCode
    }
}
`
export const PHONE_SIGNUP = gql`
mutation PhoneSignUp($phoneNumber: String!, $countryId: String!) 
{
    phoneSignUp(phoneNumber: $phoneNumber, countryId: $countryId) 
    {
        status
        id,
    }
}
`

export const PHONE_SIGNUP_VERIFY_CODE = gql`
mutation PhoneSignUpVerifyCode($phoneNumber: String!, $code: String!) 
{
    phoneSignUpVerifyCode(phoneNumber: $phoneNumber, code: $code) 
    {
        a,
        r,
        sid
    }
}
`

export const SET_PASSWORD = gql`
mutation SetPassword($password:String!){
    setPassword(password:$password){
        status
    }
}
`

export const SET_UPLINE = gql`
mutation SetUpline($uplineMemberId:String){
    setUpline(uplineMemberId:$uplineMemberId){
        status
    }
}
`

export const PHONE_SIGNIN = gql`
mutation PhoneSignIn($phoneNumber: String!) 
{
    phoneSignIn(phoneNumber: $phoneNumber) 
    {
        status,
        id
    }
}
`

export const PHONE_SIGNIN_VERIFY_CODE = gql`
mutation PhoneSignInVerifyCode($phoneNumber: String!, $code: String!) 
{
    phoneSignInVerifyCode(phoneNumber: $phoneNumber, code: $code) 
    {
        a,
        r,
        sid
    }
}
`

export const SIGN_IN = gql`
mutation SignIn($phoneNumber:String!, $password:String!){
    signIn(phoneNumber:$phoneNumber, password:$password){
        a,
        r,
        sid
    }
}
`;

export const LOGOUT = gql`
mutation Logout{
    logout{
        status
    }
}
`;

export const FORGOT_PASSWORD = gql`
mutation ForgotPassword($email:String!){
    forgotPassword(email:$email){
        status,
    }
}
`;

export const FORGOT_PASSWORD_PHONE = gql`
mutation ForgotPasswordPhone($phoneNumber:String!, $code: String!) {
    forgotPasswordPhone(phoneNumber:$phoneNumber, code: $code){
        a,
        r,
        sid
    }
}
`;

export const FIRST_LOGIN_REQUEST_CODE = gql`
mutation FirstLoginRequestCode($phoneNumber:String!){
    firstLoginRequestCode(phoneNumber:$phoneNumber){
        a,
        r,
        sid
    }
}
`;

export const FORGOT_PASSWORD_REQUEST_CODE = gql`
mutation ForgotPasswordRequestCode($phoneNumber:String!){
    forgotPasswordRequestCode(phoneNumber:$phoneNumber){
        a,
        r,
        sid
    }
}
`;

export const PHONE_FIRST_SIGNIN = gql`
mutation PhoneFirstSignIn($phoneNumber:String!, $code: String!){
    phoneFirstSignIn(phoneNumber:$phoneNumber, code: $code){
        a,
        r,
        sid
    }
}
`;