import { gql } from "@apollo/client";

export const GET_STATISTICS = gql`
query Statistics{
    statistics{
        id,
        value,
        name,
        order,
        dataType,
    }
}
`