import { Select } from "antd";
import { useIntl } from "react-intl";

export const TenureDropdownHelper = (minValue: number, maxValue: number) => {
    let options = [];
    const intl = useIntl();
    let forLastNum: number = 0;
    options.push(<Select.Option key={minValue || 0} value={minValue}>{intl.formatMessage({ id: "BY_MONTHS" }).replace("{0}", minValue?.toString())}</Select.Option>);

    for (let i = 6; i <= maxValue; i = i + 6) {
        forLastNum = i === maxValue ? i : i + 6;
        options.push(<Select.Option key={i} value={i}>{intl.formatMessage({ id: "BY_MONTHS" }).replace("{0}", i.toString())}</Select.Option>);
    }
    if (forLastNum > maxValue) options.push(<Select.Option key={maxValue} value={maxValue}>{intl.formatMessage({ id: "BY_MONTHS" }).replace("{0}", maxValue?.toString())}</Select.Option>);

    return options;
}
