import { Button, Layout, Typography } from "antd"
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import logo from './assets/images/logo.svg'
const { Content } = Layout;
const { Title } = Typography;

const NoMatch = () => {
    return (
        <Layout>
            <Content className="anonymous-container">
                <div className="logo-container">
                    <img src={logo} alt="iffinance-logo" />
                </div>
                <div className="footer-container">
                    <Title level={3}><FormattedMessage id="NOT_FOUND_TITLE" /></Title>
                    <Title level={4}><FormattedMessage id="NOT_FOUND_CONTENT" /></Title>
                    <br />
                    <Link to="/">
                        <Button type="primary"><FormattedMessage id="GENERAL_HOME" /></Button>
                    </Link>
                </div>

                <div className="footer-container font-caption">
                    <FormattedMessage id="COPY_RIGHT" />
                </div>
            </Content>
        </Layout>
    );
}

export default NoMatch;