import { Suspense, useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import GeneralFooter from "./shared/components/footer/GeneralFooter";
import GeneralHeader from "./shared/components/header/GeneralHeader";
import Home from './pages/home/Home';
import { Layout, Spin, notification } from 'antd';
import { CLEAR_NOTIFICATION, useLayout } from './hooks/layout/LayoutContext';
import { routes, RouteInfo } from './routes';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { GET_LOANS_SELECTION } from './shared/gql/loan.gql';
import { GET_USER_PROFILE } from './shared/gql/profile.gql';
import { SET_CURRENT_USER, CLEAR_CURRENT_USER, SET_LOGIN_STATUS, useAuth } from './hooks/auth/AuthContext';
import { client, authClient } from './apollo-clients';
import { LOGOUT } from './pages/auth/auth.gql';
import { clearAllTokens } from './shared/helpers/set-token-cookies.helper';
import NoMatch from './NoMatch';
import Login from './pages/auth/Login';
import FirstLogin from './pages/auth/FirstLogin';
import ForgotPassword from './pages/auth/ForgotPassword';
import ContactUs from './pages/contact_us/ContactUs';
import AboutUs from './pages/about_us/AboutUs';
import FinancingPersonal from './pages/financing/financing-personal/FinancingPersonal';
import FinancingBusiness from './pages/financing/financing-business/FinancingBusiness';
import TermsAndConditions from './pages/terms_and_conditions/TermsAndConditions';
import FinancingApplicationCompletion from './pages/financing/FinancingApplicationCompletion';

const { Content } = Layout;

const AppProtected = () => {
    const [collapsed, setCollapsed] = useState(window.innerWidth < 769 ? true : false);
    const { data: loanData } = useQuery(GET_LOANS_SELECTION, { fetchPolicy: 'cache-and-network' });
    const { authState, authDispatch } = useAuth();
    const { layoutState, layoutDispatch } = useLayout();
    const [getUserProfile, { data, error, called, refetch }] = useLazyQuery(GET_USER_PROFILE, {
        fetchPolicy: 'cache-and-network',
        errorPolicy: "all"
    });
    const [signOut, { data: signOutData }] = useMutation(LOGOUT, { fetchPolicy: "no-cache", errorPolicy: "all" });

    useEffect(() => {
        if (authState.isLogin) {
            called && refetch ? refetch() : getUserProfile();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState.isLogin]);


    useEffect(() => {
        if (signOutData && signOutData.logout) {
            clearAllTokens();
            authDispatch({ type: CLEAR_CURRENT_USER });
            authDispatch({ type: SET_LOGIN_STATUS, payload: false });
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signOutData])

    const toggleSideNav = () => {
        setCollapsed(!collapsed);
    };

    const logout = () => {
        signOut();
        signOutCleanUp();
    }

    const signOutCleanUp = () => {
        clearAllTokens();
        authDispatch({ type: CLEAR_CURRENT_USER });
        authDispatch({ type: SET_LOGIN_STATUS, payload: false });
        client.resetStore();
        authClient.resetStore();
    }

    //Set user profile is profile data changed
    useEffect(() => {
        if (data && data?.userProfile && !error) {
            authDispatch({ type: SET_CURRENT_USER, payload: data });
        } else if (error) {
            authDispatch({ type: SET_LOGIN_STATUS, payload: false });
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);


    useEffect(() => {
        if (layoutState.showNotification && layoutState.showNotification.message) {
            openNotificationWithIcon();
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layoutState.showNotification])

    const openNotificationWithIcon = () => {
        notification[layoutState?.showNotification?.type]({
            message: layoutState?.showNotification.message,
            description: layoutState?.showNotification?.description,
        });
        layoutDispatch({ type: CLEAR_NOTIFICATION })
    };

    return (<Spin tip="Loading..." spinning={layoutState.showLoading}>
        <Layout className="app-container">

            <Layout className="site-layout">
                <GeneralHeader collapsed={collapsed}
                    additionalRoute={loanData?.loans || []} toggleSideNav={toggleSideNav}
                    logout={logout}
                />
                <Content
                    className="site-layout-background">
                    <Routes>
                        <Route path='/' element={<Home />} />

                        <Route path="/login" element={
                            authState.isLogin
                                ? <Navigate to={{ pathname: '/' }} />
                                : <Login />
                        } />

                        <Route path="/auth" element={
                            authState.isLogin
                                ? <Navigate to={{ pathname: '/' }} />
                                : <Login />
                        } />

                        <Route path="/first-login" element={
                            authState.isLogin
                                ? <Navigate to={{ pathname: '/' }} />
                                : <FirstLogin />
                        } />

                        <Route path="/forgot-password" element={
                            authState.isLogin
                                ? <Navigate to={{ pathname: '/' }} />
                                : <ForgotPassword />
                        } />

                        <Route path="/contact-us" element={
                            <ContactUs />
                        } />

                        <Route path="/about-us" element={
                            <AboutUs />
                        } />

                        <Route path="/financing/personal" element={
                            <FinancingPersonal />
                        } />

                        <Route path="/financing/business" element={
                            <FinancingBusiness />
                        } />

                        <Route path="/financing/completion" element={
                            <FinancingApplicationCompletion />
                        } />

                        <Route path="/termsAndConditions" element={
                            <TermsAndConditions />
                        } />

                        {authState.isLogin ?
                            routes.map((route: RouteInfo, i) => {
                                return (
                                    <Route key={i} path={route.path} element={
                                        <Suspense fallback={<Home />}>
                                            <route.component routes={route.routes} />
                                        </Suspense>
                                    } />
                                );
                            }) :
                            <Route path="*" element={<NoMatch />} />}
                    </Routes>
                </Content>
                <GeneralFooter />
            </Layout>

        </Layout>
    </Spin>);
}

export default AppProtected;