import { Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.less';
import AppProtected from './AppProtected';
//Import i18n files
import messages_en from './assets/i18n/en.json';
import message_zh_CN from './assets/i18n/zh_CN.json';
import message_ms from './assets/i18n/ms.json';
import { IntlProvider } from 'react-intl';
import { useLayout } from './hooks/layout/LayoutContext';
import { BackTop } from 'antd';
import jwtDecode from 'jwt-decode';
import { SET_LOGIN_STATUS, useAuth } from './hooks/auth/AuthContext';
import { useCookies } from 'react-cookie';
import Home from './pages/home/Home';


const App = () => {
  const { authDispatch } = useAuth();
  const [cookies] = useCookies();
  const { layoutState } = useLayout();

  const messages: any = {
    'en': messages_en,
    'zh-CN': message_zh_CN,
    'ms': message_ms
  };

  // First load after login
  useEffect(() => {
    const token = cookies['scf-rt'];
    if (token) {
      const { isActive }: any = jwtDecode(token);
      if (isActive) {
        authDispatch({ type: SET_LOGIN_STATUS, payload: token ? true : false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IntlProvider locale={layoutState.locale} messages={messages[layoutState.locale]}>
        <Routes>
          <Route path="*" element={
              <Suspense fallback={<Home />}>
                <BackTop />
                <AppProtected />
              </Suspense>
          } />
        </Routes>
    </IntlProvider>
  );
}

export default App;
