import NoMatch from "./NoMatch";
import React from "react";
export interface RouteInfo {
	path: string;
	component: any;
	routes?: RouteInfo[];
	lazy?: boolean;
	protected?: boolean;
}

const ReactLazyPreload = (importStatement: any) => {
	const Component: any = React.lazy(importStatement);
	Component.preload = importStatement;
	return Component;
};

const MyLoan = ReactLazyPreload(() => import("./pages/account/my-loan/MyLoan"));
MyLoan.preload();

const MyLoanDetails = ReactLazyPreload(() => import("./pages/account/my-loan/MyLoanDetails"));
MyLoanDetails.preload();

const Repayment = ReactLazyPreload(() => import("./pages/account/repayment/Repayment"));
Repayment.preload();

const PaymentHistory = ReactLazyPreload(() => import("./pages/account/payment_history/PaymentHistory"));
PaymentHistory.preload();

const PaymentHistoryDetails = ReactLazyPreload(() => import("./pages/account/payment_history/PaymentHistoryDetails"));
PaymentHistoryDetails.preload();

const ForgotPassword = ReactLazyPreload(() => import("./pages/auth/ForgotPassword"));
ForgotPassword.preload();

export const routes: RouteInfo[] = [
	{
		path: "/account/my-loan/details/:id",
		component: MyLoanDetails,
		protected: true,
	},
	{
		path: "/account/my-loan",
		component: MyLoan,
		protected: true,
	},
	{
		path: "/account/repayment/:selectedRefNo",
		component: Repayment,
		protected: true,
	},
	{
		path: "/account/repayment",
		component: Repayment,
		protected: true,
	},
	{
		path: "/account/payment-history/payment-details/:id",
		component: PaymentHistoryDetails,
		protected: true,
	},
	{
		path: "/account/payment-history",
		component: PaymentHistory,
		protected: true,
	},
	{
		path: "*",
		component: NoMatch,
	}
];
