import { FormattedMessage } from "react-intl";
import { BusinessLoanCardComponent, PersonalLoanCardComponent } from "./FinancingComponents";
import "./FinancingContainer.less";

const FinancingContainer = () => {
    return (
        <div className="content-container financing-container">
            <div className="financing-header" >
                <div className="financing-content-title header-1">
                    <FormattedMessage id="FINANCING_OPTIONS" />
                </div>
                <div className="financing-container-subtitle font-body-2">
                    <FormattedMessage id="FINANCING_OPTIONS_DESC" />
                </div>
            </div>
            <div className="financing-content-container">
                <PersonalLoanCardComponent />
                <BusinessLoanCardComponent />
            </div>
        </div>
    )
}

export default FinancingContainer;