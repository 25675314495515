import { Button, Col, Form, Row, Upload } from "antd";
import { useEffect, useState } from "react";
import { UploadOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from "react-intl";
import { DELETE_UPLOADED_LOAN_DOCUMENTS, GET_LOAN_APPLICATION_BY_ID, UPLOAD_LOAN_DOCUMENTS, APPLY_LOAN_COMPLETE, LOAN_CANCEL } from "../Financing.gql";
import { useMutation, useQuery } from "@apollo/client";
import useNotification from "../../../hooks/layout/useNotification";
import { useNavigate } from "react-router-dom";
import "../Financing.less";
import { LoanApplicationCancelModal } from "../FinancingComponents";
interface Props {
    form: any;
}

const FinancingBusinessUploadSBForm = ({ form }: Props) => {
    const intl = useIntl();
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const [sbIdentityCardList, setSbIdentityCard] = useState<any[]>([]);
    const [sbCompanyBankStatementList, setSbCompanyBankStatement] = useState<any[]>([]);
    const [sbCompanyFinancialStatementList, setSbCompanyFinancialStatementList] = useState<any[]>([]);
    const [sbPersonalIncomeTaxList, setSbPersonalIncomeTaxList] = useState<any[]>([]);
    const [sbPaySlipList, setSbPaySlipList] = useState<any[]>([]);
    const [sbPesonalBankStatementList, setSbPesonalBankStatementList] = useState<any[]>([]);
    const [sbBankLoanStatementList, setSbBankLoanStatementList] = useState<any[]>([]);
    const [sbFormsMAList, setSbFormsMAList] = useState<any[]>([]);
    const [sbKwspStatementList, setSbKwspStatementList] = useState<any[]>([]);

    const navigate = useNavigate();
    const [loanApplicationInfo, setloanApplicationInfo] = useState<any>();

    const { data } = useQuery(GET_LOAN_APPLICATION_BY_ID, { variables: { id: loanApplicationInfo?.applicationId || '' }, fetchPolicy: 'cache-and-network' });
    const [uploadDocuments, { data: uploadData, error: uploadError }] = useMutation(UPLOAD_LOAN_DOCUMENTS,
        { awaitRefetchQueries: true, refetchQueries: [{ query: GET_LOAN_APPLICATION_BY_ID, variables: { id: loanApplicationInfo?.applicationId, fetchPolicy: 'cache-and-network' } },], errorPolicy: 'all' });
    const [applyComplete, { data: applyCompleteData }] = useMutation(APPLY_LOAN_COMPLETE);
    const [deleteUploadedDocuments, { data: deleteUploadedData, error: deleteUploadedError }] = useMutation(DELETE_UPLOADED_LOAN_DOCUMENTS);
    const [cancelLoan, { data: cancelLoanData, error: cancelLoanError }] = useMutation(LOAN_CANCEL);
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    useEffect(() => {
        if (data) {
            const LoanData = data && data?.loanApplicationById?.documents;
            if (LoanData) {
                setSbIdentityCard(LoanData['SB_IDENTITY_CARD'] ? LoanData['SB_IDENTITY_CARD'].map((m: any, index: any) => { return { name: sbIdentityCardList[index]?.name, status: 'done', url: m } }) : []);
                setSbCompanyBankStatement(LoanData['SB_COMPANY_BANK_STATEMENT'] ? LoanData['SB_COMPANY_BANK_STATEMENT'].map((m: any, index: any) => { return { name: sbCompanyBankStatementList[index]?.name, status: 'done', url: m } }) : []);
                setSbCompanyFinancialStatementList(LoanData['SB_COMPANY_FINANCIAL_STATEMENT'] ? LoanData['SB_COMPANY_FINANCIAL_STATEMENT'].map((m: any, index: any) => { return { name: sbCompanyFinancialStatementList[index]?.name, status: 'done', url: m } }) : []);
                setSbPersonalIncomeTaxList(LoanData['SB_PERSONAL_INCOME_TAX'] ? LoanData['SB_PERSONAL_INCOME_TAX'].map((m: any, index: any) => { return { name: sbPersonalIncomeTaxList[index]?.name, status: 'done', url: m } }) : []);
                setSbPaySlipList(LoanData['SB_PAY_SLIP'] ? LoanData['SB_PAY_SLIP'].map((m: any, index: any) => { return { name: sbPaySlipList[index]?.name, status: 'done', url: m } }) : []);
                setSbPesonalBankStatementList(LoanData['SB_PERSONAL_BANK_STATEMENT'] ? LoanData['SB_PERSONAL_BANK_STATEMENT'].map((m: any, index: any) => { return { name: sbPesonalBankStatementList[index]?.name, status: 'done', url: m } }) : []);
                setSbBankLoanStatementList(LoanData['SB_BANK_LOAN_STATEMENT'] ? LoanData['SB_BANK_LOAN_STATEMENT'].map((m: any, index: any) => { return { name: sbBankLoanStatementList[index]?.name, status: 'done', url: m } }) : []);
                setSbFormsMAList(LoanData['SB_FORMS_MA'] ? LoanData['SB_FORMS_MA'].map((m: any, index: any) => { return { name: sbFormsMAList[index]?.name, status: 'done', url: m } }) : []);
                setSbKwspStatementList(LoanData['SB_KWSP_STATEMENT'] ? [{ name: sbKwspStatementList[0]?.name, status: 'done', url: LoanData['SB_KWSP_STATEMENT'][0] }] : []);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        const loanApplicationData = localStorage.getItem("loanApplication");
        setloanApplicationInfo(loanApplicationData ? JSON.parse(loanApplicationData) : {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (cancelLoanError) {
            if (cancelLoanError.message?.indexOf('DATA_NOT_EXISTED') > -1)
                setErrorNotification(intl.formatMessage({ id: 'CANCEL_APPLICATION_FAIL' }));
            if (cancelLoanError.message?.indexOf('INVALID_INFO') > -1)
                setErrorNotification(intl.formatMessage({ id: 'CANCEL_APPLICATION_FAIL' }));

        } else if (cancelLoanData) {
            setSuccessNotification(intl.formatMessage({ id: 'CANCEL_LOAN_SUCCESS' }));
            navigate({
                pathname: "/financing/completion",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cancelLoanData, cancelLoanError]);

    useEffect(() => {
        if (uploadError) {
            setErrorNotification(intl.formatMessage({ id: 'UPLOAD_UNSUCCESSFUL' }));
        } else if (uploadData) {
            setSuccessNotification(intl.formatMessage({ id: 'UPLOAD_SUCCESSFUL' }));
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadData, uploadError]);

    useEffect(() => {
        if (deleteUploadedError) {
            setErrorNotification(intl.formatMessage({ id: 'DELETE_UPLOADED_UNSUCCESSFUL' }));
        } else if (deleteUploadedData) {
            // setData(deleteUploadedData.deleteUploadedProperty);
            setSuccessNotification(intl.formatMessage({ id: 'DELETE_UPLOADED_SUCCESSFUL' }));
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteUploadedData, deleteUploadedError]);

    useEffect(() => {
        if (applyCompleteData && applyCompleteData?.applyLoanComplete?.status === "SUCCESS") {
            navigate({
                pathname: "/financing/completion",
                search: "?type=success"
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applyCompleteData])

    const upload = (uploadFile: File, fileType: string) => {
        const variables: any = {
            uploadLoanInput: {
                loanId: loanApplicationInfo?.applicationId,
                uploadCategory: fileType,
                fileUpload: uploadFile ?? new File([], '', { type: 'text-plain' }),
            },
        }
        uploadDocuments({
            variables
        });
    }

    const handleUpload = async (e: any, type: string) => {
        if (e.file instanceof File) {
            upload(e.file, type);
        }
        return e && e.fileList;
    };

    const cancelApplication = () => {
        const variables: any = {
            id: loanApplicationInfo?.applicationId
        }
        cancelLoan({ variables })
    }

    const handleCancel = () => {
        setModalVisible(true);
    }

    const checkFileType = (e: any) => {
        if (e.type === 'image/jpeg' || e.type === 'image/jpg' || e.type === 'image/png' || e.type === 'application/pdf') {
            return false;
        }

        setErrorNotification(intl.formatMessage({ id: 'FORM_ACCEPTED_FILE_FORMAT' }, {
            0: intl.formatMessage({ id: "FORM_IMG_FORMAT" }),
        }));
        return Upload.LIST_IGNORE;
    }

    const handleRemoveloanUpload = async (e: any, type: string) => {
        let variables: any = {
            loanId: loanApplicationInfo?.applicationId,
            deleteCategory: type,
            deleteUrl: e.url,
        };
        deleteUploadedDocuments({ variables });
    }

    const onFinish = () => {
        let variables: any = {
            id: loanApplicationInfo?.applicationId,
        }
        if (sbIdentityCardList.length > 0 && sbCompanyBankStatementList.length > 0 && sbCompanyFinancialStatementList.length > 0
            && sbPersonalIncomeTaxList.length > 0 && sbPesonalBankStatementList.length > 0 && sbBankLoanStatementList.length > 0 && sbFormsMAList.length > 0) {
            applyComplete({
                variables,
            });
        }
    }

    return (
        <>
            <div className="financing-form-container">
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={[32, 0]}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                            <FormattedMessage id="UPLOAD_DOCUMENTS_SB" />
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <Form.Item getValueFromEvent={e => handleUpload(e, "SBIdentityCard")} name="SBIdentityCard" label={intl.formatMessage({ id: "FORM_SB_IC" })}
                                rules={[
                                    { required: true, message: intl.formatMessage({ id: 'FORM_SB_IC_REQUIRED' }) }]}
                            >
                                <Upload name="sbIdentityCardList" maxCount={10}
                                    fileList={sbIdentityCardList}
                                    onChange={(e: any) => {
                                        setSbIdentityCard(e.fileList)
                                    }}
                                    onRemove={e => handleRemoveloanUpload(e, "SBIdentityCard")}
                                    beforeUpload={checkFileType} showUploadList={{ showRemoveIcon: true }}
                                >
                                    <Button disabled={sbIdentityCardList.length >= 10} icon={<UploadOutlined />}><FormattedMessage id="UPLOAD" /></Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <Form.Item getValueFromEvent={e => handleUpload(e, "SBCompanyBankStatement")} name="SBCompanyBankStatement" label={intl.formatMessage({ id: "FORM_SB_BANK_STATEMENT" })}
                                rules={[
                                    { required: true, message: intl.formatMessage({ id: 'FORM_SB_BANK_STATEMENT_REQUIRED' }) }]}
                            >
                                <Upload name="sbCompanyBankStatementList" maxCount={3}
                                    fileList={sbCompanyBankStatementList}
                                    onChange={(e: any) => {
                                        setSbCompanyBankStatement(e.fileList)
                                    }}
                                    onRemove={e => handleRemoveloanUpload(e, "SBCompanyBankStatement")}
                                    beforeUpload={checkFileType} showUploadList={{ showRemoveIcon: true }}
                                >
                                    <Button disabled={sbCompanyBankStatementList.length >= 3} icon={<UploadOutlined />}><FormattedMessage id="UPLOAD" /></Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <Form.Item getValueFromEvent={e => handleUpload(e, "SBCompanyFinancialStatement")} name="SBCompanyFinancialStatement" label={intl.formatMessage({ id: "FORM_SB_COMPANY_FINANCIAL" })}
                                rules={[
                                    { required: true, message: intl.formatMessage({ id: 'FORM_SB_COMPANY_FINANCIAL_REQUIRED' }) }]}
                            >
                                <Upload name="sbCompanyFinancialStatementList" maxCount={3}
                                    fileList={sbCompanyFinancialStatementList}
                                    onChange={(e: any) => {
                                        setSbCompanyFinancialStatementList(e.fileList)
                                    }}
                                    onRemove={e => handleRemoveloanUpload(e, "SBCompanyFinancialStatement")}
                                    beforeUpload={checkFileType} showUploadList={{ showRemoveIcon: true }}
                                >
                                    <Button disabled={sbCompanyFinancialStatementList.length >= 3} icon={<UploadOutlined />}><FormattedMessage id="UPLOAD" /></Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <Form.Item getValueFromEvent={e => handleUpload(e, "SBPersonalIncomeTax")} name="SBPersonalIncomeTax" label={intl.formatMessage({ id: "FORM_SB_PERSONAL_INCOMETAX" })}
                                rules={[
                                    { required: true, message: intl.formatMessage({ id: 'FORM_SB_PERSONAL_INCOMETAX_REQUIRED' }) }]}
                            >
                                <Upload name="sbPersonalIncomeTaxList" maxCount={20}
                                    fileList={sbPersonalIncomeTaxList}
                                    onChange={(e: any) => {
                                        setSbPersonalIncomeTaxList(e.fileList)
                                    }}
                                    onRemove={e => handleRemoveloanUpload(e, "SBPersonalIncomeTax")}
                                    beforeUpload={checkFileType} showUploadList={{ showRemoveIcon: true }}
                                >
                                    <Button disabled={sbPersonalIncomeTaxList.length >= 20} icon={<UploadOutlined />}><FormattedMessage id="UPLOAD" /></Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <Form.Item getValueFromEvent={e => handleUpload(e, "SBPaySlip")} name="SBPaySlip" label={intl.formatMessage({ id: "FORM_SB_PAY_SLIP" })}
                            >
                                <Upload name="sbPaySlipList" maxCount={3}
                                    fileList={sbPaySlipList}
                                    onChange={(e: any) => {
                                        setSbPaySlipList(e.fileList)
                                    }}
                                    onRemove={e => handleRemoveloanUpload(e, "SBPaySlip")}
                                    beforeUpload={checkFileType} showUploadList={{ showRemoveIcon: true }}
                                >
                                    <Button disabled={sbPaySlipList.length >= 3} icon={<UploadOutlined />}><FormattedMessage id="UPLOAD" /></Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <Form.Item getValueFromEvent={e => handleUpload(e, "SBPesonalBankStatement")} name="SBPesonalBankStatement" label={intl.formatMessage({ id: "FORM_SB_PERSONAL_BANK" })}
                                rules={[
                                    { required: true, message: intl.formatMessage({ id: 'FORM_SB_PERSONAL_BANK_REQUIRED' }) }]}
                            >
                                <Upload name="sbPesonalBankStatementList" maxCount={3}
                                    fileList={sbPesonalBankStatementList}
                                    onChange={(e: any) => {
                                        setSbPesonalBankStatementList(e.fileList)
                                    }}
                                    onRemove={e => handleRemoveloanUpload(e, "SBPesonalBankStatement")}
                                    beforeUpload={checkFileType} showUploadList={{ showRemoveIcon: true }}
                                >
                                    <Button disabled={sbPesonalBankStatementList.length >= 3} icon={<UploadOutlined />}><FormattedMessage id="UPLOAD" /></Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <Form.Item getValueFromEvent={e => handleUpload(e, "SBBankLoanStatement")} name="SBBankLoanStatement" label={intl.formatMessage({ id: "FORM_SB_BANK_LOAN" })}
                                rules={[
                                    { required: true, message: intl.formatMessage({ id: 'FORM_SB_BANK_LOAN_REQUIRED' }) }]}
                            >
                                <Upload name="sbBankLoanStatementList" maxCount={3}
                                    fileList={sbBankLoanStatementList}
                                    onChange={(e: any) => {
                                        setSbBankLoanStatementList(e.fileList)
                                    }}
                                    onRemove={e => handleRemoveloanUpload(e, "SBBankLoanStatement")}
                                    beforeUpload={checkFileType} showUploadList={{ showRemoveIcon: true }}
                                >
                                    <Button disabled={sbBankLoanStatementList.length >= 3} icon={<UploadOutlined />}><FormattedMessage id="UPLOAD" /></Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <Form.Item getValueFromEvent={e => handleUpload(e, "SBFormsMA")} name="SBFormsMA" label={intl.formatMessage({ id: "FORM_SB_MA" })}
                                rules={[
                                    { required: true, message: intl.formatMessage({ id: 'FORM_SB_MA_REQUIRED' }) }]}
                            >
                                <Upload name="sbFormsMAList" maxCount={4}
                                    fileList={sbFormsMAList}
                                    onChange={(e: any) => {
                                        setSbFormsMAList(e.fileList)
                                    }}
                                    onRemove={e => handleRemoveloanUpload(e, "SBFormsMA")}
                                    beforeUpload={checkFileType} showUploadList={{ showRemoveIcon: true }}
                                >
                                    <Button disabled={sbFormsMAList.length >= 4} icon={<UploadOutlined />}><FormattedMessage id="UPLOAD" /></Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <Form.Item getValueFromEvent={e => handleUpload(e, "SBKwspStatement")} name="SBKwspStatement" label={intl.formatMessage({ id: "FORM_SB_KWSP" })}
                            >
                                <Upload name="sbKwspStatementList" maxCount={1}
                                    fileList={sbKwspStatementList}
                                    onChange={(e: any) => {
                                        setSbKwspStatementList(e.fileList)
                                    }}
                                    onRemove={e => handleRemoveloanUpload(e, "SBKwspStatement")}
                                    beforeUpload={checkFileType} showUploadList={{ showRemoveIcon: true }}
                                >
                                    <Button disabled={sbKwspStatementList.length >= 1} icon={<UploadOutlined />}><FormattedMessage id="UPLOAD" /></Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                        <Form.Item className="form-button">
                            <Button type="default" onClick={handleCancel}>
                                {intl.formatMessage({ id: 'FORM_BUTTON_CANCEL_APPLICATION' })}
                            </Button>
                            <Button type="primary" htmlType="submit"
                                disabled={sbIdentityCardList.length === 0 || sbCompanyBankStatementList.length === 0 || sbCompanyFinancialStatementList.length === 0
                                    || sbPersonalIncomeTaxList.length === 0 || sbPesonalBankStatementList.length === 0 || sbBankLoanStatementList.length === 0 || sbFormsMAList.length === 0}
                            >
                                {intl.formatMessage({ id: 'FORM_BUTTON_SUBMIT' })}
                            </Button>
                        </Form.Item>
                    </Col>
                </Form>
            </div>
            <LoanApplicationCancelModal
                title={intl.formatMessage({ id: "FORM_CANCEL_APPLICATION" })}
                visible={modalVisible}
                setVisible={setModalVisible}
                cancelApplication={cancelApplication}
            />
        </>
    )
}

export default FinancingBusinessUploadSBForm;