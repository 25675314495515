import { FormattedMessage } from 'react-intl';
import './ContactUsContainer.less';
import ContactUsForm from './ContactUsForm';

const ContactUsContainer = () => {
    return (
        <div className="content-container contact-us-container">
            <div className="contact-us">
                <span className="header-1"><FormattedMessage id="CONTACT_US" /></span>
                <div className="contact-us-information">
                    <div>
                        <span className="font-subtitle1"><FormattedMessage id="ADDRESS" />:</span>
                        <span className="font-body-2">Infinitec Finsource SDN. BHD. (1395512-U)</span>
                        <span className="font-body-2">No. 13-3, Jalan PJU 5/12, Dataran Sunway, Kota Damansara, 47810 Petaling Jaya, Selangor Darul Ehsan</span>
                    </div>
                    <div>
                        <span className="font-subtitle1"><FormattedMessage id="EMAIL_ADDRESS" />:</span>
                        <span className="font-body-2">info@iffinance.asia</span>
                    </div>
                    <div>
                        <span className="font-subtitle1"><FormattedMessage id="TEL" />:</span>
                        <span className="font-body-2">603-6143 3226</span>
                    </div>
                    <div>
                        <span className="font-subtitle1"><FormattedMessage id="BUSINESS_HOUR" />:</span>
                        <span className="font-body-2"><FormattedMessage id="BUSINESS_HOUR_PERIOD" /></span>
                        <span className="font-body-2"><FormattedMessage id="BUSINESS_HOUR_DAY" /></span>
                    </div>
                </div>
            </div>
            <div className="contact-us-form">
                <ContactUsForm />
            </div>
        </div>
    )
}

export default ContactUsContainer;