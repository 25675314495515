import { FormattedMessage } from "react-intl";
import { Button, Carousel, Typography } from "antd";
import "./PinnedContainer.less";
import { BusinessCarouselComponent, PersonalLoanCarouselComponent } from "./CarouselComponents";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/auth/AuthContext";
import { SET_CURRENT_PATH, useLayout } from "../../../hooks/layout/LayoutContext";

const PinnedContainer = () => {
    const { authState } = useAuth();
    const { layoutDispatch } = useLayout();
    const navigate = useNavigate();

    return (
        <div className="content-container pinned-container">
            <div className="pinned-content">
                <Typography.Title level={1}>
                    <FormattedMessage id="PINNED_TITLE" />
                </Typography.Title>
                <FormattedMessage id="PINNED_CONTENT" />
                <div className="pinned-button">
                    <Button type="primary">
                        <Link to="/financing/personal" onClick={() => {
                            layoutDispatch({ type: SET_CURRENT_PATH, payload: '/financing/personal' });
                            navigate('/financing/personal');
                        }}>
                            <FormattedMessage id="APPLY_NOW" />
                        </Link>
                    </Button>
                    <Button>
                        <Link to={authState.isLogin ? "/account/my-loan" : "/auth"} onClick={() => {
                            if (authState.isLogin) {
                                layoutDispatch({ type: SET_CURRENT_PATH, payload: '/account/my-loan' });
                                navigate('/account/my-loan');
                            } else {
                                navigate('/auth');
                            }

                        }}>
                            <FormattedMessage id="CHECK_APPLICATION" />
                        </Link>
                    </Button>
                </div>
            </div>
            <div className="pinned-content">
                <Carousel autoplay>
                    <div>
                        <BusinessCarouselComponent />
                    </div>
                    <div>
                        <PersonalLoanCarouselComponent />
                    </div>
                </Carousel>
            </div>
        </div>
    );
}

export default PinnedContainer;