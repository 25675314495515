import "./Financing.less";
import checkSvg from "../../assets/images/CheckCircle.svg";
import { FormattedMessage } from "react-intl";
import { useEffect, useState } from "react";
import moment from "moment";
import NumberFormat from "react-number-format";
import { Link, useLocation } from "react-router-dom";
import { CloseCircleFilled } from "@ant-design/icons";
import { Button } from "antd";

const FinancingApplicationCompletion = () => {

    const [loanApplicationInfo, setloanApplicationInfo] = useState<any>();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);

    useEffect(() => {
        const loanApplicationData = localStorage.getItem("loanApplication");
        setloanApplicationInfo(loanApplicationData ? JSON.parse(loanApplicationData) : {});
        if (query.get('type') === "success")
            setIsSuccess(true);
        return () => {
            localStorage.removeItem("loanApplication");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="financing-application-banner">
                <div className="financing-application-body">
                    {isSuccess ?
                        <img src={checkSvg} alt="checkSvg" />
                        : <div className="fail-icon"><CloseCircleFilled /></div>
                    }
                    <span className="header-2">
                        <FormattedMessage id="FINANCE_FORM_THANK_YOU" />
                    </span>

                    <span className="header-2 application-title">
                        {isSuccess ?
                            <FormattedMessage id="FINANCE_FORM_SUBMITTED" />
                            : <FormattedMessage id="FINANCE_FORM_CANCELLED" />
                        }
                    </span>
                    {isSuccess ?
                        <div className="application-details-container">
                            <div className="application-details-column">
                                <span><FormattedMessage id="APPLICATION_ID" />:</span>
                                <span><FormattedMessage id="APPLICATION_STATUS" />:</span>
                                <span><FormattedMessage id="DATE_TIME" />:</span>
                                <span><FormattedMessage id="LOAN_TYPE" />:</span>
                                <span><FormattedMessage id="LOAN_AMOUNT" />:</span>
                                {loanApplicationInfo?.businessType ? <span><FormattedMessage id="FORM_LOAN_BUSINESS_TYPE" /></span> : null}
                            </div>
                            <div className="application-details-column">
                                <span>{loanApplicationInfo?.refId}</span>
                                <span>{loanApplicationInfo?.applicationStatus}</span>
                                <span>{moment(loanApplicationInfo?.dateTime).format("YYYY-MM-D, HH:mm:ss")}</span>
                                <span>{loanApplicationInfo?.loanType}</span>
                                <span>
                                    <NumberFormat
                                        value={loanApplicationInfo?.loanAmount}
                                        thousandSeparator={true}
                                        displayType="text"
                                        prefix="MYR "
                                    />
                                </span>
                                {loanApplicationInfo?.businessType ? <span>{loanApplicationInfo?.businessType}</span> : null}
                            </div>
                        </div>
                        : null
                    }
                    <div className={isSuccess ? "application-button-container" : "application-button-container application-button-container-fail"} >
                        <Button type="default">
                            <Link to="/">
                                <FormattedMessage id="FINANCE_FORM_HOMEPAGE" />
                            </Link>
                        </Button>
                        <Button type="primary">
                            <Link to="/first-login">
                                <FormattedMessage id="FINANCE_FORM_LOGIN_NEW" />
                            </Link>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FinancingApplicationCompletion;