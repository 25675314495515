import { animated, config, useSpring } from "@react-spring/web";

interface Props {
    value: number;
    dataType: string;
}

const StatisticAnimationComponent = ({ value, dataType }: Props) => {
    const { number } = useSpring({
        reset: true,
        reverse: false,
        from: { number: 0 },
        number: value,
        delay: 200,
        config: config.slow,
    });

    if (dataType === "NUMBER") {
        return <animated.div className="statistic-values">{number.to(n => Math.floor(n).toLocaleString('EN-us'))}</animated.div>;
    } else {
        return <animated.div className="statistic-values">{number.to(n => n.toFixed(0) + '%')}</animated.div>;
    }


}

export default StatisticAnimationComponent;