import { Select } from "antd";
import { useEffect, useState } from "react";
import { SET_LANGUAGE, useLayout } from "../../../hooks/layout/LayoutContext";
import './LanguageSwitcher.less';
const { Option } = Select;

const LanguageSwitcher = () => {
    const { layoutDispatch } = useLayout();
    const [language, setLanguage] = useState<string>('');

    useEffect(() => {
        const locale: any = localStorage.getItem('ifxl');
        setLanguage(locale || 'en');		
		// eslint-disable-next-line
	}, []);

    const setLocale = (loc: string) => {
        localStorage.setItem('ifxl', loc);
        layoutDispatch({ type: SET_LANGUAGE, payload: loc })
    }

    const languages = [
        { label: 'EN', value: 'en' },
        { label: '中文', value: 'zh-CN' },
        { label: 'BM', value: 'ms' }
    ]

    const handleChange = (value: string) => {
        setLocale(value)
        setLanguage(value);
    }

    return (
        <Select className="language-selection" value={language} style={{ width: 80 }} onChange={handleChange}>
            {
                languages.map((d: any) => (
                    <Option key={d.value} value={d.value}>{d.label}</Option>
                ))
            }
        </Select>
    )
}

export default LanguageSwitcher;