import { Button, Layout, Menu, Divider as AntDivider, Space, Dropdown } from 'antd';
import { Link, useLocation, useNavigate } from "react-router-dom";
import './GeneralHeader.less';
import { ReactComponent as SmallIcon } from "../../../assets/images/logo.svg";
import LanguageSwitcher from '../../widgets/language-switcher/LanguageSwitcher';
import { FormattedMessage } from 'react-intl';
import { MenuOutlined, MailOutlined, PayCircleOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { SET_CURRENT_PATH, useLayout } from '../../../hooks/layout/LayoutContext';
import LoanCalculator from '../../../pages/loan_calculator/LoanCalculator';
import { useState } from 'react';
import { useAuth } from '../../../hooks/auth/AuthContext';
import SubMenu from 'antd/lib/menu/SubMenu';
import DownOutlined from '@ant-design/icons/lib/icons/DownOutlined';


const { Header } = Layout;
const { Item, Divider } = Menu;

interface Props {
  collapsed: boolean;
  additionalRoute?: any[];
  toggleSideNav?: () => void;
  logout?: () => void;
}

const GeneralHeader = ({ collapsed, additionalRoute, toggleSideNav, logout }: Props) => {
  const { layoutState } = useLayout();
  const navigate = useNavigate();
  const [visible, setVisible] = useState<boolean>(false);
  const [isHideHeaderMenu] = useState(window.innerWidth < 769 ? true : false);
  const { authState } = useAuth();
  const { layoutDispatch } = useLayout();
  const { pathname } = useLocation();

  const redirect = (path: string, redirect: boolean = true) => {
    layoutDispatch({ type: SET_CURRENT_PATH, payload: path });
    if (redirect) {
      navigate(path);
    }
  }
  const loginNavMobile = (
    <>
      {
        isHideHeaderMenu && authState.isLogin && authState?.userProfile?.fullName ?
          <>
            <Item key="0" className="menu-item"><Link to="#" ><FormattedMessage id="AUTH_WELCOME" />, {authState.userProfile.fullName}</Link></Item>
            <Divider />
          </>
          : null
      }
      <Menu.Item key="/account/my-loan" icon={<PayCircleOutlined />}>
        <a href="#/" onClick={() => redirect('/account/my-loan')}><FormattedMessage id="MY_LOAN" /></a>
      </Menu.Item>
      <Menu.Item key="/account/repayment" icon={<MailOutlined />}>
        <a href="#/" onClick={() => redirect('/account/repayment')}><FormattedMessage id="NAV_REPAYMENT" /></a>
      </Menu.Item>
      <Menu.Item key="/account/payment-history" icon={<MailOutlined />}>
        <a href="#/" onClick={() => redirect('/account/payment-history')}><FormattedMessage id="NAV_PAYMENT_HISTORY" /></a>
      </Menu.Item>
    </>
  );

  const menuItems = (
    <>
      <Item key="/loan-calculator" className="menu-item"><div onClick={() => { setVisible(true); redirect('/loan-calculator', false) }}><FormattedMessage id="LOAN_CALCULATOR" /></div></Item>
      <Item key="/about-us" className="menu-item"><div onClick={() => { redirect('/about-us'); }}><FormattedMessage id="ABOUT_US" /></div></Item>
      <Item key="/contact-us" className="menu-item"><div onClick={() => { redirect('/contact-us'); }}><FormattedMessage id="CONTACT_US" /></div></Item>
      {
        isHideHeaderMenu &&
        <>
          <Divider />
          {
            additionalRoute?.map((d: any) => (
              <Menu.Item key={d.id} className="menu-item">
                <div onClick={() => { redirect(d.route); }}>{d?.name[layoutState.locale || '']}</div>
              </Menu.Item>
            ))
          }
          <Divider />
          {
            !authState.isLogin
              ? <Item key="/auth" className="menu-item"><div onClick={() => { redirect('/auth'); }}><FormattedMessage id="AUTH_BUTTON_LOGIN" /></div></Item>
              : <Item key="/auth" className="menu-item" onClick={logout}><div onClick={() => { redirect('/auth'); }}><FormattedMessage id="NAV_LOGOUT" /></div></Item>
          }
        </>
      }
    </>
  );

  const loginNav = (
    <Menu className="header-user-dropdown" mode="horizontal" selectedKeys={[layoutState.currentPath]}>
      <Menu.Item key="1" icon={<UserOutlined />}>
        {authState.isLogin && authState?.userProfile?.fullName ? authState.userProfile.fullName : null}
      </Menu.Item>
      <Menu.Item key="/account/my-loan" icon={<PayCircleOutlined />}>
        <a href="#/" onClick={() => redirect('/account/my-loan')}><FormattedMessage id="MY_LOAN" /></a>
      </Menu.Item>
      <Menu.Item key="/account/repayment" icon={<MailOutlined />}>
        <a href="#/" onClick={() => redirect('/account/repayment')}><FormattedMessage id="NAV_REPAYMENT" /></a>
      </Menu.Item>
      <Menu.Item key="/account/payment-history" icon={<MailOutlined />}>
        <a href="#/" onClick={() => redirect('/account/payment-history')}><FormattedMessage id="NAV_PAYMENT_HISTORY" /></a>
      </Menu.Item>
      <Menu.Item key="/auth" icon={<LogoutOutlined />} onClick={logout}>
        <a href="#/" onClick={() => redirect('/auth')}><FormattedMessage id="NAV_LOGOUT" /></a>
      </Menu.Item>
    </Menu>
  );

  const goToLogin = () => {
    navigate("/auth");
  };

  return (
    <>
      <Header className="site-layout-header" style={{ padding: 0 }}>
        <div className="header-container" >
          <div className="header-logo">
            <Link to="/" onClick={() => redirect('/')}>
              <SmallIcon />
            </Link>
          </div>
          <div className="header-actions">
            {
              !isHideHeaderMenu &&
              <>
                {/* <FinancingSelection loanRoute={additionalRoute} /> */}
                <Menu mode="horizontal" selectedKeys={[layoutState.currentPath]}
                  className={['/financing/personal', '/financing/business'].includes(pathname) ? "financing-button" : "menu"}>
                  <SubMenu key="subMenu" title={
                    <Space>
                      <FormattedMessage id="FINANCING" />
                      <DownOutlined className="icon" />
                    </Space>
                  }>
                    {
                      additionalRoute?.map((d: any) => (
                        <Menu.Item key={d.id} className="menu-item">
                          <div onClick={() => { redirect(d.route); }}>{d?.name[layoutState.locale || '']}</div>
                        </Menu.Item>
                      ))
                    }
                  </SubMenu>
                </Menu>
                <div className="header-menu">
                  <Menu mode="horizontal" selectedKeys={[layoutState.currentPath]} className="menu" overflowedIndicator={<MenuOutlined className="more-icon" />}>
                    {menuItems}
                  </Menu>
                </div>
                <AntDivider type="vertical" className="center-divider" />
                <LanguageSwitcher />
                {
                  authState.isLogin && authState?.userProfile?.fullName
                    ? <Dropdown overlay={loginNav} className="profile-menu" trigger={['hover', 'click']}>
                      <a className="ant-dropdown-link" href="/#" >  <UserOutlined /> </a>
                    </Dropdown>
                    : <Button className="login-button" onClick={goToLogin}><FormattedMessage id="AUTH_BUTTON_LOGIN" /></Button>
                }
              </>
            }
            {
              isHideHeaderMenu &&
              <>
                <LanguageSwitcher />
                <AntDivider type="vertical" className="center-divider" />
                <div className="header-menu">
                  <Menu mode="horizontal" selectedKeys={[layoutState.currentPath]} className="menu" overflowedIndicator={<MenuOutlined className="more-icon" />}>
                    {authState.isLogin && loginNavMobile}
                    {menuItems}
                  </Menu>
                </div>

              </>
            }
            {/* {
              isHideHeaderMenu &&
              <>
                <LanguageSwitcher />
                {
                  React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                    className: 'trigger',
                    onClick: toggleSideNav,
                  })
                }
                <Drawer
                  title={
                    <div className="side-menu-icon">
                      <SmallIcon />
                    </div>
                  }
                  placement="left"
                  closable={false}
                  onClose={toggleSideNav}
                  visible={!collapsed}>
                  <Menu onClick={toggleSideNav} className="side-menu" mode="vertical">
                    {menuItems}
                  </Menu>
                </Drawer>
              </>
            } */}
          </div>
        </div>
      </Header >
      <LoanCalculator visible={visible} setVisible={setVisible} ></LoanCalculator>
    </>
  )
}

export default GeneralHeader;