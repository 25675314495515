import './Auth.less';
import { Typography, Button } from "antd";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import SetPasswordForm from "./SetPasswordForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
const { Title } = Typography;

const ForgotPassword = () => {
	const [currentStep, setCurrentStep] = useState(0);

	const next = () => {
		setCurrentStep(currentStep + 1);
	};
	

	const steps = [
		{
			title: "AUTH_FORGOT_PASSWORD",
			content: <ForgotPasswordForm next={next} />,
		},
		{
			title: "AUTH_REGISTER_STEP2",
			content: <SetPasswordForm />,
		},
	];

	return (
		<>
			<div className="auth-bg-container">
				<div className="auth-container">
					<div className="cover-container">
						<Title level={4}>
							<FormattedMessage id={steps[currentStep].title} />
						</Title>
						<div className="auth-content">
							{steps[currentStep].content}
							<div className="forgot-password-container">
								<Link to="/auth">
									<Button type="link" size="small">
										<FormattedMessage id="AUTH_BACK_LOGIN" />
									</Button>
								</Link>
							</div>
						</div>

					</div>
				</div>
			</div>
		</>
	);
};

export default ForgotPassword;
