import { FormattedMessage } from "react-intl"
import businessLoanImage from "../../../assets/images/Banner-landing-businessloan.png";
import personalLoanImage from "../../../assets/images/Banner-landing-personalloan.png";


export const BusinessCarouselComponent = () => {
    return (
        <div className="carousel-container">
            <div className="carousel-content">
                <div className="carousel-content-title header-2">
                    <FormattedMessage id="BUSINESS_LOAN" />
                </div>
                <div className="carousel-content-subtitle font-subtitle1">
                    <FormattedMessage id="BUSINESS_LOAN_DESC" />
                </div>
                <ul>
                    <li><FormattedMessage id="BUSINESS_CAROUSEL_SUBTITLE_1" /></li>
                    <li><FormattedMessage id="BUSINESS_CAROUSEL_SUBTITLE_2" /></li>
                    <li><FormattedMessage id="BUSINESS_CAROUSEL_SUBTITLE_3" /></li>
                    <li><FormattedMessage id="BUSINESS_CAROUSEL_SUBTITLE_4" /></li>
                </ul>
            </div>
            <div className="image-container">
                <img src={businessLoanImage} alt="businessLoanImage" />
            </div>
        </div>
    )
}

export const PersonalLoanCarouselComponent = () => {
    return (
        <div className="carousel-container">
            <div className="carousel-content">
                <div className="carousel-content-title header-2">
                    <FormattedMessage id="PERSONAL_LOAN" />
                </div>
                <div className="carousel-content-subtitle font-subtitle1">
                    <FormattedMessage id="PERSONAL_LOAN_DESC" />
                </div>
                <ul>
                    <li><FormattedMessage id="PERSONAL_CAROUSEL_SUBTITLE_1" /></li>
                    <li><FormattedMessage id="PERSONAL_CAROUSEL_SUBTITLE_2" /></li>
                    <li><FormattedMessage id="PERSONAL_CAROUSEL_SUBTITLE_3" /></li>
                    <li><FormattedMessage id="PERSONAL_CAROUSEL_SUBTITLE_4" /></li>
                </ul>
            </div>
            <div className="image-container">
                <img src={personalLoanImage} alt="personalLoanImage" />
            </div>
        </div>
    )
}
