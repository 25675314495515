import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input } from "antd";
import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SET_PASSWORD } from "./auth.gql";
import useNotification from "../../hooks/layout/useNotification";

const SetPasswordForm = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const { setSuccessNotification } = useNotification();
    const [setPassword, { data, loading }] = useMutation(SET_PASSWORD, { fetchPolicy: "no-cache", errorPolicy: "all" });

    useEffect(() => {
        if (data && data.setPassword) {
            setSuccessNotification(intl.formatMessage({id: 'AUTH_PASSWORD_SET'}));
            navigate("/auth");
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const onFinish = (values: any) => {
        setPassword({
            variables: {
                password: Buffer.from(values.password).toString('base64')
            }
        })
    };

    return (<Form
        name="form"
        onFinish={onFinish}
    >
        <Form.Item
            name="password"
            rules={[
                { required: true, message: intl.formatMessage({ id: 'LOGIN_PASSWORD_REQUIRED' }) },
                { min: 8, message: intl.formatMessage({ id: 'LOGIN_PASSWORD_REQUIRED' }) }]}>
            <Input.Password placeholder={intl.formatMessage({ id: 'GENERAL_PASSWORD' })} />
        </Form.Item>
        <Form.Item
            name="confirmPassword"
            dependencies={['password']}
            rules={[{ required: true, message: intl.formatMessage({ id: 'LOGIN_PASSWORD_REQUIRED' }) },
            ({ getFieldValue }) => ({
                validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                },
            })]}>
            <Input.Password placeholder={intl.formatMessage({ id: 'AUTH_CONFIRM_PASSWORD' })} />
        </Form.Item>
        <Form.Item className="auth-button">
            <Button block type="primary" htmlType="submit" loading={loading}>
                <FormattedMessage id="GENERAL_NEXT" />
            </Button>
        </Form.Item>
    </Form>)
}

export default SetPasswordForm;