import { Modal } from "antd";
import { Dispatch, SetStateAction } from "react";
import { FormattedMessage, useIntl } from "react-intl"
import "./Financing.less";

export const PersonalFinancingRequirements = () => {
    return (
        <div className="financing-component-list">
            <div className="header-2">
                <FormattedMessage id="REQUIREMENTS" />
            </div>
            <ul>
                <li><FormattedMessage id="PERSONAL_FINANCING_REQ_1" /></li>
                <li><FormattedMessage id="PERSONAL_FINANCING_REQ_2" /></li>
                <li><FormattedMessage id="PERSONAL_FINANCING_REQ_3" /></li>
                <li><FormattedMessage id="PERSONAL_FINANCING_REQ_4" /></li>
                <li><FormattedMessage id="PERSONAL_FINANCING_REQ_5" /></li>
                <li><FormattedMessage id="PERSONAL_FINANCING_REQ_6" /></li>
                <li><FormattedMessage id="PERSONAL_FINANCING_REQ_7" /></li>
            </ul>
        </div>
    )
}

export const PersonalFinancingDocuments = () => {
    return (
        <div className="financing-component-list">
            <div className="header-2">
                <FormattedMessage id="DOCUMENTS_NEEDED" />
            </div>
            <ul>
                <li><FormattedMessage id="PERSONAL_FINANCING_DOC_1" /></li>
                <li><FormattedMessage id="PERSONAL_FINANCING_DOC_2" /></li>
                <li><FormattedMessage id="PERSONAL_FINANCING_DOC_3" /></li>
                <li><FormattedMessage id="PERSONAL_FINANCING_DOC_4" /></li>
                <li><FormattedMessage id="PERSONAL_FINANCING_DOC_5" /></li>
            </ul>
        </div>
    )
}

export const PersonalFinancingCashFlowNeeds = () => {
    return (
        <div className="financing-component-information">
            <div className="header-2 financing-title">
                <FormattedMessage id="CASH_FLOW_NEEDS" />
            </div>
            <ul>
                <li><FormattedMessage id="PERSONAL_FINANCING_CF_1" /></li>
                <li><FormattedMessage id="PERSONAL_FINANCING_CF_2" /></li>
                <li><FormattedMessage id="PERSONAL_FINANCING_CF_3" /></li>
                <li><FormattedMessage id="PERSONAL_FINANCING_CF_4" /></li>
            </ul>
        </div>
    )
}

export const PersonalFinancingCashFlowEvents = () => {
    return (
        <div className="financing-component-information">
            <div className="header-2 financing-title">
                <FormattedMessage id="CASH_FLOW_EVENTS" />
            </div>
            <ul>
                <li><FormattedMessage id="PERSONAL_FINANCING_CFE_1" /></li>
                <li><FormattedMessage id="PERSONAL_FINANCING_CFE_2" /></li>
                <li><FormattedMessage id="PERSONAL_FINANCING_CFE_3" /></li>
                <li><FormattedMessage id="PERSONAL_FINANCING_CFE_4" /></li>
                <li><FormattedMessage id="PERSONAL_FINANCING_CFE_5" /></li>
                <li><FormattedMessage id="PERSONAL_FINANCING_CFE_6" /></li>
                <li><FormattedMessage id="PERSONAL_FINANCING_CFE_7" /></li>
            </ul>
        </div>
    )
}

export const BusinessFinancingLoanNeeds = () => {
    return (
        <div className="financing-component-information">
            <div className="header-2 financing-title">
                <FormattedMessage id="BUSINESS_LOAN" />
            </div>
            <ul>
                <li><FormattedMessage id="BUSINESS_FINANCING_LN_1"/></li>
                <li><FormattedMessage id="BUSINESS_FINANCING_LN_2"/></li>
                <li><FormattedMessage id="BUSINESS_FINANCING_LN_3"/></li>
                <li><FormattedMessage id="BUSINESS_FINANCING_LN_4"/></li>
                <li><FormattedMessage id="BUSINESS_FINANCING_LN_5"/></li>
            </ul>
        </div>
    )
}

export const BusinessFinancingLoanExpansion = () => {
    return (
        <div className="financing-component-information">
            <div className="header-2 financing-title">
                <FormattedMessage id="BUSINESS_LOAN_EXPANSION" />
            </div>
            <ul>
                <li><FormattedMessage id="BUSINESS_FINANCING_LE_1" /></li>
                <li><FormattedMessage id="BUSINESS_FINANCING_LE_2" /></li>
                <li><FormattedMessage id="BUSINESS_FINANCING_LE_3" /></li>
                <li><FormattedMessage id="BUSINESS_FINANCING_LE_4" /></li>
                <li><FormattedMessage id="BUSINESS_FINANCING_LE_5" /></li>
            </ul>
        </div>
    )
}

export const BusinessFinancingSoleProprietor = () => {
    return (
        <div className="financing-component-list">
            <div className="header-2">
                <FormattedMessage id="SOLE_PROPRIETOR" />
            </div>
            <ul>
                <li><FormattedMessage id="BUSINESS_FINANCING_SP_1" /></li>
                <li><FormattedMessage id="BUSINESS_FINANCING_SP_2" /></li>
                <li><FormattedMessage id="BUSINESS_FINANCING_SP_3" /></li>
                <li><FormattedMessage id="BUSINESS_FINANCING_SP_4" /></li>
                <li><FormattedMessage id="BUSINESS_FINANCING_SP_5" /></li>
                <li><FormattedMessage id="BUSINESS_FINANCING_SP_6" /></li>
                <li><FormattedMessage id="BUSINESS_FINANCING_SP_7" /></li>
            </ul>
        </div>
    )
}

export const BusinessFinancingSdnBhd = () => {
    return (
        <div className="financing-component-list">
            <div className="header-2">
                <FormattedMessage id="SDN_BHD" />
            </div>
            <ul>
                <li><FormattedMessage id="BUSINESS_FINANCING_SB_1" /></li>
                <li><FormattedMessage id="BUSINESS_FINANCING_SB_2" /></li>
                <li><FormattedMessage id="BUSINESS_FINANCING_SB_3" /></li>
                <li><FormattedMessage id="BUSINESS_FINANCING_SB_4" /></li>
                <li><FormattedMessage id="BUSINESS_FINANCING_SB_5" /></li>
                <li><FormattedMessage id="BUSINESS_FINANCING_SB_6" /></li>
                <li><FormattedMessage id="BUSINESS_FINANCING_SB_7" /></li>
                <li><FormattedMessage id="BUSINESS_FINANCING_SB_8" /></li>
            </ul>
        </div>
    )
}

interface Props {
    title: string;
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
    cancelApplication: any;
}

export const LoanApplicationCancelModal = ({ title, visible, setVisible, cancelApplication }: Props) => {
    const intl = useIntl();

    const handleCancel = () => {
        setVisible(false);
    };

    const handleOk = () => {
        setVisible(false);
        cancelApplication();
    };

    return (
        <Modal
            title={title}
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            okText={intl.formatMessage({ id: "SUBMIT" })}
            cancelText={intl.formatMessage({ id: "CANCEL" })}
        >
            <p>
                <FormattedMessage id="FORM_CANCEL_CONFIRMATION" />
            </p>
        </Modal>
    )
}