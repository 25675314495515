import { Button } from "antd";
import moment from "moment";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { FormattedMessage, useIntl } from "react-intl";
import { setGeneralCookie } from "../../shared/helpers/set-general-cookie.helper";
interface Props {
    isSignUp?: boolean;
    phoneNumber: string | undefined;
    hasError: boolean;
    requestStatus: () => void;
    setError: Dispatch<SetStateAction<any>>;
}
const CodeRequestButton = ({ requestStatus, isSignUp = false,
    phoneNumber, hasError, setError }: Props) => {
    // eslint-disable-next-line
    const [cookies, setCookie, removeCookie] = useCookies();
    const [cooldown, setCooldown] = useState<number>(0);
    const [cooldownTimer, setCooldownTimer] = useState<number>();
    const intl = useIntl();

    useEffect(() => {
        const cooldownFromCookie = cookies[`c-cooldown-${isSignUp}`];
        if (cooldownFromCookie) {
            const timePassed = moment().unix() - (+cooldownFromCookie);
            //due to latency, reduce 1 sec
            const remainingSeconds = timePassed > 59 ? 0 : (59 - timePassed);
            setCooldown(remainingSeconds);
            initiateTimer(remainingSeconds);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const clearTimer = () => {
            removeCookie(`c-cooldown-${isSignUp}`);
            if (cooldownTimer) {
                clearInterval(cooldownTimer);
            }
        }
        return () => clearTimer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cooldownTimer])

    useEffect(() => {
        if (hasError) {
            removeCookie(`c-cooldown-${isSignUp}`);
            setCooldown(0);
            if (cooldownTimer) {
                clearInterval(cooldownTimer);
            }
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasError]);

    const initiateTimer = (amount: number) => {
        const cooldownInterval: number = window.setInterval(() => {
            if (amount > 0) {
                setCooldown(amount--);
            } else {
                setCooldown(0);
                clearInterval(cooldownInterval);
            }
        }, 1000);
        setCooldownTimer(cooldownInterval);
    }

    const requestCode = () => {
        if(!phoneNumber) {
            setError(intl.formatMessage({ id: "PHONE_NUMBER_REQUIRED" }));
        }
        else if (phoneNumber && cooldown === 0) {
            //1 minute cooldown
            setGeneralCookie(`c-cooldown-${isSignUp}`, moment().unix().toString(), 59);
            setCooldown(59);
            if (cooldownTimer) {
                clearInterval(cooldownTimer);
            }
            initiateTimer(59);
            requestStatus();
        }
    }


    return (
        <Button className="request-btn" disabled={cooldown > 0} onClick={requestCode}>
            {(!cooldown || cooldown === 0) ? <FormattedMessage id="AUTH_REQUEST_CODE" />
                : (cooldown > 1 ? <FormattedMessage id="AUTH_COOL_DOWNS" values={{ cooldown }} />
                    : <FormattedMessage id="AUTH_COOL_DOWN" values={{ cooldown }} />)}
        </Button>)

}

export default CodeRequestButton;