import './Auth.less';
import { Alert, Button, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import PhoneInput from '../../shared/widgets/phone-input/PhoneInput';
import { useMutation } from '@apollo/client';
import { SIGN_IN } from './auth.gql';
import { SET_LOGIN_STATUS, useAuth } from '../../hooks/auth/AuthContext';
import { setTokenCookie } from '../../shared/helpers/set-token-cookies.helper';
import useSpinner from '../../hooks/layout/useSpinner';
import { useNavigate } from "react-router-dom";
import useNotification from "../../hooks/layout/useNotification";

const LoginPasswordForm = () => {
    const intl = useIntl();
    const { setLoading } = useSpinner();
    const [signIn, { data, loading, error: signInError }] = useMutation(SIGN_IN, { fetchPolicy: "no-cache", errorPolicy: "all" });
    const [error, setError] = useState<string>();
    const { authDispatch } = useAuth();
    const [form] = Form.useForm();
    const [countryId, setCountryId] = useState<string>('MY');
    const navigate = useNavigate();
    const { setErrorNotification } = useNotification();

    useEffect(() => {
        if (loading) setLoading(true);
        else setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    useEffect(() => {
        if (data && data.signIn) {
            const { a, r, sid } = data.signIn;
            if(a) setTokenCookie('scf-at', a);
            if(r) {
                setTokenCookie('scf-rt', r);
                authDispatch({ type: SET_LOGIN_STATUS, payload: true });
            }
            if(sid) setTokenCookie('scf-sid', sid);
            navigate("/");
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    useEffect(() => {
        if (signInError) {
            if (signInError.message?.indexOf('INVALID_LOGIN') > -1) {
                setError('AUTH_ERROR_INVALID_LOGIN');
            } else if (signInError.message?.indexOf('INVALID_PHONE_LOGIN') > -1) {
                setError('AUTH_ERROR_INVALID_LOGIN_PHONE');
            } else if (signInError.message?.indexOf('ACCOUNT_LOCKED') > -1) {
                setError('AUTH_ERROR_ACCOUNT_LOCKED');
            } else if (signInError.message?.indexOf('ACCOUNT_NOT_EXISTED') > -1) {
                setError('AUTH_ERROR_ACCOUNT_NOT_EXISTED');
            } else if (signInError.message?.indexOf('FIRST_LOGIN') > -1) {
                setErrorNotification(intl.formatMessage({ id: 'AUTH_ERROR_FIRST_LOGIN' }));
                navigate("/first-login");
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signInError])

    const onFinish = (values: any) => {
        signIn({
            variables: {
                phoneNumber: values.userName,
                password: Buffer.from(values.password).toString('base64')
            }
        });
    };

    const selectCountryId = (countryId: string) => {
        setCountryId(countryId);
    }

    return (
        <Form
            name="form"
            form={form}
            initialValues={{ isMemberId: '0' }}
            onFinish={onFinish}
        >
            {error && <Alert
                className="auth-error"
                message={<FormattedMessage id={error} />}
                type="error"
                showIcon
            />}

            <Form.Item
                name="userName"
                rules={[
                    { required: true, message: intl.formatMessage({ id: 'LOGIN_USERNAME_REQUIRED' }) },
                    { min: 8, message: intl.formatMessage({ id: 'LOGIN_USERNAME_VALIDATE' }) }]}>
                <PhoneInput selectedCountryId={countryId} selectCountryId={selectCountryId} />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[{ required: true, message: intl.formatMessage({ id: 'LOGIN_PASSWORD_REQUIRED' }) }]}>
                <Input.Password maxLength={30} placeholder={intl.formatMessage({ id: 'GENERAL_PASSWORD' })} />
            </Form.Item>
            <Form.Item className="auth-button">
                <Button block type="primary" htmlType="submit" loading={loading}>
                    <FormattedMessage id="AUTH_BUTTON_LOGIN" />
                </Button>
            </Form.Item>
        </Form>
    );
}

export default LoginPasswordForm;