import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { useLayout } from "../../../hooks/layout/LayoutContext";
import { GET_STATISTICS } from "../../../shared/gql/statistic.gql";
import StatisticAnimationComponent from "./StatisticAnimation";
import "./StatisticContainer.less"

const StatisticContainer = () => {
    const [getStatistics, { data: getData }] = useLazyQuery(GET_STATISTICS, { fetchPolicy: 'cache-and-network' });
    const { layoutState } = useLayout();

    useEffect(() => {
        getStatistics();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="content-container statistic-container">
            {getData && getData.statistics.map((val: any) => {
                return (
                    <div className="statistic-content" key={val?.id}>
                        <div className="statistic-title">{val?.name[layoutState.locale || '']} </div>
                        <StatisticAnimationComponent
                            value={parseFloat(val?.value)}
                            dataType={val?.dataType}
                        />
                    </div>
                )

            })}
        </div>
    )
}

export default StatisticContainer;

