import { gql } from "@apollo/client";

export const GET_LOANS_SELECTION = gql`
query Loans{
    loans{
        id,
        name,
        route
    }
}
`;